import React, { useEffect, useRef, useState } from "react";
import Select, { components } from "react-select";
import Button from "../../components/ui/Button";
import { MultiSelect } from "react-multi-select-component";
import GTM from "../../GTM";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
    getPresentDayFormat,
    getPreviousDays,
    getPreviousDaysEnd,
    getPreviousDaysStart,
    CUSTOM_DATE,
    PREVIOUS_MONTH,
    PREVIOUS_QUARTER,
} from "../../utils/helpers";
import PoliciesAndDivisions from "./PoliciesAndDivisions";
import { FaAngleRight } from "react-icons/fa"; // Import the arrow icons
import moment from "moment";

const ReportFilters = ({
    allowedPoliciesAndDivisions,
    handleApplyFilter,
    selectedValues,
    updateSelectedValues,
    selectedFilters,
    coverageTypeOptions,
    reportingPeriod,
    status,
    reportingGroup,
    reportRanDate,
    reportViewType,
    defaultReportingPeriod,
    customDateRange,
    handleDateRangeChange,
    organizationName,
    userSelectedOrganizationReports,
    reportId,
    relatedOrganizationNames,
    selectedCoverageType,
    handleCoverage,
    selectedStatus,
    handleStatus,
    selectedPolicyDivision,
    handlePolicyDivision,
    includeHistoricalLeave,
    periodScheduleType,
    periodStatus,
    protectionStatus,
    defaultIncludeHistoricalLeaveValue,
    defaultPeriodStatusValue,
    defaultPeriodScheduleTypeValue,
    defaultProtectionStatusValue,
    onIncludeHistoricalValueChange,
    onPeriodScheduleTypeValueChange,
    onPeriodStatusValueChange,
    onProtectionStatusValueChange,
    onReportingPeriodChange,
    onHandleReset,
    enableReset,
    enableApply,
    onHandleOutsideClick,
    disableConfirm,
}) => {
    const gtm = new GTM(null);
    const handleGtmTags = (label) => {
        gtm.Event(
            "custom_event",
            "filter",
            "select",
            label,
            "report detail",
            reportViewType.toLowerCase(),
            "field_interaction",
            ""
        );
    };
    const [enableApplyButton, setEnableApply] = useState(enableApply);
    const [selectedReportingGroup, setSelectedReportingGroup] = useState(
        selectedFilters?.reportingPeriod?.length > 0
            ? selectedFilters?.reportingPeriod
            : reportingGroup
    );

    const commonSelectProps = {
        isSearchable: false,
        styles: {
            control: (provided, state) => ({
                ...provided,
                borderTop: "1px",
                borderColor: "none",
                borderLeft: "0px",
                borderRight: "1px",
                borderRadius: "0px",
                marginTop: "-12px",
                boxShadow: state.isFocused && "none",
                backgroundColor: "none",
                color: state.isSelected && "white",
            }),
            option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "#FFFFFF" : "black",
                backgroundColor: state.isSelected && "#037CB7",
                margin: "0px",
                "&:hover": {
                    backgroundColor: "#037CB7",
                    color: "#FFFFFF",
                    cursor: "pointer",
                },
            }),
            placeholder: (provided) => ({
                ...provided,
                color: "#fffff",
            }),
            singleValue: (provided) => ({
                ...provided,
                marginLeft: "0px",
                color: "white",
            }),
            valueContainer: (provided) => ({
                ...provided,
                paddingLeft: "0px",
            }),
            menu: (provided) => ({
                ...provided,
                margin: "0px",
                zIndex: 10,
            }),
        },
        components: {
            DropdownIndicator: ({ selectProps }) => {
                if (selectProps.category === "reportingPeriod") {
                    return (
                        <div>
                            <i
                                className="fas fa-chevron-down"
                                style={{ marginRight: 8 }}
                            ></i>
                            <i className="fa-solid fa-calendar"></i>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <i
                                className="fas fa-chevron-down"
                                style={{ marginRight: 8 }}
                            ></i>
                        </div>
                    );
                }
            },
        },
    };

    const CustomOption = ({ children, ...props }) => {
        return (
            <components.Option {...props}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {children}
                    {props.data.value === CUSTOM_DATE && (
                        <FaAngleRight
                            style={{ marginLeft: "auto", fontSize: "1.3em" }}
                        />
                    )}
                </div>
            </components.Option>
        );
    };
    const handleReportingPeriodChange = (selectedOption) => {
        if (selectedOption.value !== CUSTOM_DATE) {
            onReportingPeriodChange(selectedOption);
            setEnableApply(true);
            updateSelectedValues({
                ...selectedValues,
                reportingPeriod: selectedOption,
            });
        }
        handleGtmTags("reporting period");

        if (selectedOption.value === CUSTOM_DATE) {
            setShowCalendar(true); // Show the date range picker
        } else {
            setShowCalendar(false);
            handleDateRangeChange({
                reset: true,
            });
        }
    };

    const handleClearDates = () => {
        handleDateRangeChange({
                reset: true,
        });
       
        setEnableApply(true);
        
    }

    const handleConfirmDates = () => {
        handleDateRangeChange({
                confirm: true,
        });
        setShowCalendar(false); // Show the date range picker
        updateSelectedValues({
            ...selectedValues,
            reportingPeriod: {
                value: CUSTOM_DATE,
                label:
                    CUSTOM_DATE +
                    moment(customDateRange[0].startDate).format(mmDateFormat) +
                    " - " +
                    moment(customDateRange[0].endDate).format(mmDateFormat),
            },
        });
    }

    const [showCalendar, setShowCalendar] = useState(false);
    const daterangeref = useRef(null);
    const popupRef = useRef(null);
    const isClickedOutside = useOutsideClick(daterangeref, popupRef);

    const [searchTerm, setSearchTerm] = useState("");

    const handlePolicyCheckboxChange = (policyId) => {
        const policyDivisions =
            filteredPoliciesAndDivisions.find(
                (item) => item.policyId === policyId
            )?.divisions || [];
        const divisionIds = policyDivisions.map(
            (division) => `${policyId}-${division.divisionId}`
        );

        handlePolicyDivision((prevSelected) => {
            if (prevSelected.includes(policyId)) {
                return prevSelected.filter(
                    (item) => item !== policyId && !divisionIds.includes(item)
                );
            } else {
                return [...prevSelected, policyId, ...divisionIds];
            }
        });
        setEnableApply(true);
    };
    const filteredPoliciesAndDivisions =
        searchTerm.length < 3
            ? allowedPoliciesAndDivisions
            : allowedPoliciesAndDivisions.filter((item) => {
                  return (
                      item.policyId
                          ?.toLowerCase()
                          .includes(searchTerm.toLowerCase()) ||
                      item.divisions.some(
                          (division) =>
                              division.divisionName
                                  ?.toLowerCase()
                                  .includes(searchTerm.toLowerCase()) ||
                              division.divisionId
                                  ?.toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                      )
                  );
              });

    const handleDivisionCheckboxChange = (divisionId, policyId) => {
        handlePolicyDivision((prevSelected) => {
            const policyDivisions =
                filteredPoliciesAndDivisions
                    .find((item) => item.policyId === policyId)
                    ?.divisions.map(
                        (division) => `${policyId}-${division.divisionId}`
                    ) || [];

            const newSelected = prevSelected.includes(divisionId)
                ? prevSelected.filter((item) => item !== divisionId)
                : [...prevSelected, divisionId];

            const allPolicyDivisionsSelected = policyDivisions.every((id) =>
                newSelected.includes(id)
            );

            if (allPolicyDivisionsSelected) {
                return [...newSelected, policyId];
            } else {
                return newSelected.filter((item) => item !== policyId);
            }
        });
        setEnableApply(true);
    };
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleSelectAll = () => {
        const allItems = filteredPoliciesAndDivisions.flatMap((item) => [
            item.policyId,
            ...item.divisions.map(
                (division) => `${item.policyId}-${division.divisionId}`
            ),
        ]);
        handlePolicyDivision(allItems);
        setEnableApply(true);
    };

    const handleUnselectAll = () => {
        handlePolicyDivision([]);
        setEnableApply(false);
    };
    const clearSearch = () => {
        setSearchTerm("");
    };

    const handleCoverageTypeChange = (selectedOptions) => {
        if(selectedOptions.length === 0){
            setEnableApply(false);
            handleCoverage(selectedOptions);
            handleGtmTags("coverage type");
        }else{
            setEnableApply(true);
            handleCoverage(selectedOptions);
            handleGtmTags("coverage type");
        }
    };

    const handleStatusChange = (selectedOptions) => {
        if(selectedOptions.length === 0){
            setEnableApply(false);
            handleStatus(selectedOptions);
            handleGtmTags("status");
        }else{
            setEnableApply(true);
            handleStatus(selectedOptions);
            handleGtmTags("status");
        }
    };

    const handleReportingGroupChange = (selectedOptions) => {
        if(selectedOptions.length === 0){
            setEnableApply(false);
            setSelectedReportingGroup(selectedOptions);
            updateSelectedValues({
                ...selectedValues,
                reportingGroup: selectedOptions,
            });
            handleGtmTags("reporting groups");
        }else{
            setEnableApply(true);
            setSelectedReportingGroup(selectedOptions);
            updateSelectedValues({
                ...selectedValues,
                reportingGroup: selectedOptions,
            });
            handleGtmTags("reporting groups");
        }
    };

    const handleReset = () => {
        onHandleReset();
        handleGtmTags("reset filters");
    };

    useEffect(() => {
        if (showCalendar) {
            setShowCalendar(false);
            onHandleOutsideClick();
        }
       
    }, [isClickedOutside]);

    
    useEffect(() => {
       setEnableApply(enableApply);
    }, [enableApply]);

    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

    const onApplyFilterClick = (item) => {
        let reportingPeriodStartDate = null;
        let reportingPeriodEndDate = null;
        if (item.reportingPeriod.value === "Previous day") {
            reportingPeriodStartDate = getPreviousDays(reportRanDate, 1);
            reportingPeriodEndDate = getPresentDayFormat(reportRanDate);
        } else if (item.reportingPeriod.value === "Last 7 days") {
            reportingPeriodStartDate = getPreviousDays(reportRanDate, 7);
            reportingPeriodEndDate = getPreviousDays(reportRanDate, 1);
        } else if (item.reportingPeriod.value === PREVIOUS_MONTH) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportRanDate,
                "month"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(reportRanDate, "month");
        } else if (item.reportingPeriod.value === PREVIOUS_QUARTER) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportRanDate,
                "quarter"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportRanDate,
                "quarter"
            );
        } else if (item.reportingPeriod.value === CUSTOM_DATE) {
            reportingPeriodStartDate = customDateRange[0].startDate;
            reportingPeriodEndDate = customDateRange[0].endDate;
        }
        handleApplyFilter({
            ...item,
            effectiveStartDate: reportingPeriodStartDate,
            effectiveEndDate: reportingPeriodEndDate,
        });
        handleGtmTags("apply filters");
    };
    let mmDateFormat = "MM/DD/YYYY";
    let fromPlaceholder = "";
    if (customDateRange[0].selectedFromDate) {
        fromPlaceholder = `Start Date: ${moment(
            customDateRange[0].selectedFromDate
        ).format(mmDateFormat)}`;
    } else {
        fromPlaceholder = "Start Date:"; // Placeholder text when startDate is null
    }

    let toPlaceholder = "";
    if (customDateRange[0].selectedEndDate) {
        toPlaceholder = `End Date: ${moment(
            customDateRange[0].selectedEndDate
        ).format(mmDateFormat)}`;
    } else {
        toPlaceholder = "End Date:"; // Placeholder text when endDate is null
    }

    const handleIncludeHistoricalValueChange = (selectedOptions) => {
        onIncludeHistoricalValueChange(selectedOptions);
    };

    const handlePeriodScheduleTypeValueChange = (selectedOptions) => {
        if(selectedOptions.length === 0){
            setEnableApply(false);
            onPeriodScheduleTypeValueChange(selectedOptions);
        }else {
            setEnableApply(true);
            onPeriodScheduleTypeValueChange(selectedOptions);
        }
    };

    const handlePeriodStatusValueChange = (selectedOptions) => {
        if(selectedOptions.length === 0){
            setEnableApply(false);
            onPeriodStatusValueChange(selectedOptions);
        }else{
            setEnableApply(true);
            onPeriodStatusValueChange(selectedOptions);
        }
    };

    const handleProtectionStatusValueChange = (selectedOptions) => {
        if(selectedOptions.length === 0){
            setEnableApply(false);
            onProtectionStatusValueChange(selectedOptions);
        }else {
            setEnableApply(true);
            onProtectionStatusValueChange(selectedOptions);
        }
    };

    // Determine border styles based on placeholder values and their conditions
    const fromIsStartDate  = fromPlaceholder === 'Start Date:';
    const toIsEndDate  = toPlaceholder === 'End Date:';
    const fromBorderStyle = !toIsEndDate ? '1px solid #015294' : (fromIsStartDate ? '1px solid #015294' : '1px solid #ccc');
    const toBorderStyle = toIsEndDate ? (fromIsStartDate ? '1px solid #ccc' : '1px solid #015294') : '1px solid #ccc';
 // Determine button states
    const isClearDisabled = fromPlaceholder === 'Start Date:';
    const isDateRange = defaultReportingPeriod.label.startsWith("Date range ") &&
                    /\b\d{2}\/\d{2}\/\d{4} - \d{2}\/\d{2}\/\d{4}\b/.test(defaultReportingPeriod.label.substring(11));
    const isConfirmDisabled = (fromPlaceholder === 'Start Date:') || (toPlaceholder === 'End Date:');
    const [disableConfirmButton, setdisableConfirmButton] = useState(false);
    useEffect(() => {
        setdisableConfirmButton(disableConfirm);
       
    }, [disableConfirm]);

    
    return (
        <div className="d-flex flex-direction">
            <div className="row" style={{ position: "relative", rowGap: 16 }}>
                <div
                    className="col-lg-3 col-md-6 col-sm-4 col-6 col-print"
                    style={{ marginTop: "-10px" }}
                >
                    <label className="pb-0 header">Organization</label>
                    <p className="sub-text">{organizationName}</p>
                </div>

                <div
                    className="col-lg-3 col-md-6 col-sm-4 col-6 col-print"
                    style={{ marginTop: "-10px" }}
                >
                    <label className="pb-0 header">Related orgs</label>
                    <p className="sub-text">
                        {userSelectedOrganizationReports[reportId]?.related
                            .length > 0 ? (
                            <div className="parameter-text d-block">
                                {relatedOrganizationNames}
                            </div>
                        ) : (
                            <span className=" d-block">None</span>
                        )}
                    </p>
                </div>
            </div>

            <div
                className="d-flex justify-content-end gap-4 apply-button"
                style={{ position: "relative", top: "-10px" }}
            >
                <div className="d-flex col-lg-1 col-md-1 col-sm-6 col-6 a-self">
                    <Button
                        text="Reset"
                        outline={true}
                        onClick={handleReset}
                        disabled={!enableReset}
                    />
                </div>
                <div className="d-flex col-lg-1 col-md-1 col-sm-6 col-6 a-self">
                    <Button
                        text="Apply"
                        onClick={() => {
                            onApplyFilterClick(selectedValues);
                        }}
                        disabled={!enableApplyButton}
                    />
                </div>
            </div>

            <div className="row" style={{ rowGap: 16, marginTop: "10px" }}>
                <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                    <label className="header pb-0">Reporting period</label>
                    <div className="reportfilter">
                        <Select
                            className=""
                            options={reportingPeriod}
                            {...commonSelectProps}
                            onChange={handleReportingPeriodChange}
                            value={defaultReportingPeriod}
                            components={{ Option: CustomOption }}
                        />
                        {showCalendar && (
                            <div
                                className="col-lg-3 col-md-6 col-sm-4 col-6 col-print"
                                ref={daterangeref}
                            >
                                <div className="reportfilter" ref={popupRef}>
                                    <div className="section-card date-range-picker">
                                        <div className="date-range-labels">
                                            <div className="from-label"
                                             style={{
                                                border: fromBorderStyle 
                                            }}>
                                                {fromPlaceholder}
                                            </div>
                                            <div className="to-label"
                                            style={{
                                                border: toBorderStyle 
                                            }}>
                                                {toPlaceholder}
                                            </div>
                                        </div>
                                        <DateRange
                                            onChange={handleDateRangeChange}
                                            months={2}
                                            //initialFocusedRange={customDateRange}
                                            ranges={customDateRange}
                                            direction="horizontal"
                                            calendarFocus="backwards"
                                            rangeColors={["#037CB7"]}
                                            minDate={fiveYearsAgo}
                                            maxDate={new Date()}
                                            showDateDisplay={false}
                                            // shownDate={new Date()}
                                        />
                                        <div className="button-container" style={{
                                                position: 'relative',
                                                bottom: '1px', // Adjust the value as needed
                                                right: '-225px',  // Adjust the value as needed
                                                display: 'flex',
                                                gap: '10px'
                                            }}>
                                                <Button
                                                    text="Clear"
                                                    outline={true}
                                                    onClick={handleClearDates}
                                                    disabled={isClearDisabled}
                                                style={{
                                                    minWidth: '80px',
                                                    fontSize: '16px', 
                                                    color: 'red'  }} 
                                                />
                                                <Button
                                                    text="Confirm"
                                                    onClick={handleConfirmDates}
                                                    disabled={isConfirmDisabled || disableConfirmButton}
                                                    style={{minWidth: '100px', // Adjust width as needed
                                                            fontSize: '16px'}} 
                                                />
                                            </div> 
                                            
                                        </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                    <label className="header pb-0">Coverage type</label>
                    <div className="reportfilter">
                        <MultiSelect
                            options={coverageTypeOptions}
                            value={selectedCoverageType}
                            labelledBy="coverageTypeList"
                            disableSearch={true}
                            isCreatable={false}
                            overrideStrings={{
                                selectSomeItems: "Add filters +",
                            }}
                            onChange={handleCoverageTypeChange}
                        />
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                    {reportViewType === "PaymentHistory" ? (
                        <label className="header pb-0">Payment Status</label>
                    ) : (
                        <label className="header pb-0">Status</label>
                    )}
                    <div className="reportfilter">
                        <MultiSelect
                            options={status}
                            value={selectedStatus}
                            labelledBy="coverageTypeList"
                            disableSearch={true}
                            isCreatable={false}
                            overrideStrings={{
                                selectSomeItems: "Add filters +",
                            }}
                            onChange={handleStatusChange}
                        />
                    </div>
                </div>
                {(reportViewType !== "Leave" ||
                    reportViewType === "Absence") && (
                    <PoliciesAndDivisions
                        filteredPoliciesAndDivisions={
                            filteredPoliciesAndDivisions
                        }
                        selectedPolicyDivision={selectedPolicyDivision}
                        handleSelectAll={handleSelectAll}
                        handleUnselectAll={handleUnselectAll}
                        searchTerm={searchTerm}
                        handleSearchChange={handleSearchChange}
                        clearSearch={clearSearch}
                        handlePolicyCheckboxChange={handlePolicyCheckboxChange}
                        handleDivisionCheckboxChange={
                            handleDivisionCheckboxChange
                        }
                    />
                )}
                {(reportViewType === "Leave" ||
                    reportViewType === "Absence") && (
                    <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                        <label className="header pb-0">
                            Include Historical Leave
                        </label>

                        <div className="reportfilter">
                            <Select
                                options={includeHistoricalLeave}
                                {...commonSelectProps}
                                value={defaultIncludeHistoricalLeaveValue}
                                onChange={handleIncludeHistoricalValueChange}
                            />
                        </div>
                    </div>
                )}
                {(reportViewType === "PaymentHistory" ||
                    reportViewType === "Leave" ||
                    reportViewType === "Absence") && (
                    <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                        <label className="header pb-0">Reporting group</label>
                        <div className="reportfilter">
                            <MultiSelect
                                options={reportingGroup}
                                value={selectedReportingGroup}
                                labelledBy="coverageTypeList"
                                disableSearch={false}
                                isCreatable={false}
                                overrideStrings={{
                                    selectSomeItems: "Add filters +",
                                }}
                                onChange={handleReportingGroupChange}
                            />
                        </div>
                    </div>
                )}
                {(reportViewType === "Leave" ||
                    reportViewType === "Absence") && (
                    <>
                        <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                            <label className="header pb-0">
                                Period Schedule Type
                            </label>

                            <div className="reportfilter">
                                <MultiSelect
                                    options={periodScheduleType}
                                    value={defaultPeriodScheduleTypeValue}
                                    labelledBy="coverageTypeList"
                                    disableSearch={true}
                                    isCreatable={false}
                                    overrideStrings={{
                                        selectSomeItems: "Add filters +",
                                    }}
                                    onChange={
                                        handlePeriodScheduleTypeValueChange
                                    }
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                            <label className="header pb-0">Period Status</label>

                            <div className="reportfilter">
                                <MultiSelect
                                    options={periodStatus}
                                    value={defaultPeriodStatusValue}
                                    labelledBy="coverageTypeList"
                                    disableSearch={true}
                                    isCreatable={false}
                                    overrideStrings={{
                                        selectSomeItems: "Add filters +",
                                    }}
                                    onChange={handlePeriodStatusValueChange}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-4 col-6 col-print">
                            <label className="header pb-0">
                                Protection Status
                            </label>

                            <div className="reportfilter">
                                <MultiSelect
                                    options={protectionStatus}
                                    value={defaultProtectionStatusValue}
                                    labelledBy="coverageTypeList"
                                    disableSearch={true}
                                    isCreatable={false}
                                    overrideStrings={{
                                        selectSomeItems: "Add filters +",
                                    }}
                                    onChange={handleProtectionStatusValueChange}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ReportFilters;
