import React, { useState } from "react";
import { Breadcrumb } from "../../components/ui/Breadcrumb";
import ReportFilters from "./ReportFilters";

function ReportViewFilter({
    organizationName,
    userSelectedOrganizationReports,
    reportId,
    relatedOrganizationNames,
    isFetching,
    title,
    coverageType,
    allowedPoliciesAndDivisions,
    handleApplyFilter,
    selectedFilters,
    coverageTypeOptions,
    reportingPeriod,
    status,
    reportingGroup,
    reportRanDate,
    reportViewType,
    defaultReportingPeriod,
    defaultReportingStatus,
    customDateRange,
    handleDateRangeChange,
    selectedCoverageType,
    handleCoverage,
    selectedStatus,
    handleStatus,
    selectedPolicyDivision,
    handlePolicyDivision,
    initialPolicyDivision,
    includeHistoricalLeave,
    periodScheduleType,
    periodStatus,
    protectionStatus,
    includeHistoricalLeaveValue,
    periodScheduleTypeValue,
    protectionStatusValue,
    periodStatusValue,
    onIncludeHistoricalValueChange,
    onPeriodScheduleTypeValueChange,
    onPeriodStatusValueChange,
    onProtectionStatusValueChange,
    onReportingPeriodChange,
    onHandleReset,
    enableReset,
    enableApply,
    onHandleOutsideClick,
    disableConfirm,
}) {
    const [selectedValues, setSelectedValues] = useState({
        reportingPeriod: {
            value: defaultReportingPeriod.value,
            label: defaultReportingPeriod.label,
        },
        reportingGroup: [{ value: "All", label: "All" }],
    });

    const updateSelectedValues = (newValues) => {
        setSelectedValues(newValues);
    };

    return (
        <>
            <Breadcrumb
                links={[
                    {
                        path: "/",
                        name: "Reports",
                    },
                    {
                        path: "/reportingcapabilities",
                        name: "Run Reports",
                    },
                    {
                        name: title,
                    },
                ]}
            />

            <section
                className="section-card container-heading data-view-card"
                style={
                    isFetching ? { pointerEvents: "none", opacity: "0.4" } : {}
                }
            >
                <div
                    className="row"
                    style={{ position: "relative", rowGap: 16 }}
                >
                    <ReportFilters
                        coverageType={coverageType}
                        allowedPoliciesAndDivisions={
                            allowedPoliciesAndDivisions
                        }
                        handleApplyFilter={handleApplyFilter}
                        selectedValues={selectedValues}
                        updateSelectedValues={updateSelectedValues}
                        selectedFilters={selectedFilters}
                        coverageTypeOptions={coverageTypeOptions}
                        reportingPeriod={reportingPeriod}
                        status={status}
                        reportingGroup={reportingGroup}
                        reportRanDate={reportRanDate}
                        reportViewType={reportViewType}
                        defaultReportingPeriod={defaultReportingPeriod}
                        defaultReportingStatus={defaultReportingStatus}
                        customDateRange={customDateRange}
                        handleDateRangeChange={handleDateRangeChange}
                        organizationName={organizationName}
                        userSelectedOrganizationReports={
                            userSelectedOrganizationReports
                        }
                        reportId={reportId}
                        relatedOrganizationNames={relatedOrganizationNames}
                        selectedCoverageType={selectedCoverageType}
                        handleCoverage={handleCoverage}
                        selectedStatus={selectedStatus}
                        handleStatus={handleStatus}
                        selectedPolicyDivision={selectedPolicyDivision}
                        handlePolicyDivision={handlePolicyDivision}
                        initialPolicyDivision={initialPolicyDivision}
                        includeHistoricalLeave={includeHistoricalLeave}
                        periodScheduleType={periodScheduleType}
                        periodStatus={periodStatus}
                        protectionStatus={protectionStatus}
                        defaultIncludeHistoricalLeaveValue={
                            includeHistoricalLeaveValue
                        }
                        defaultPeriodScheduleTypeValue={periodScheduleTypeValue}
                        defaultPeriodStatusValue={periodStatusValue}
                        defaultProtectionStatusValue={protectionStatusValue}
                        onIncludeHistoricalValueChange={
                            onIncludeHistoricalValueChange
                        }
                        onPeriodScheduleTypeValueChange={
                            onPeriodScheduleTypeValueChange
                        }
                        onPeriodStatusValueChange={onPeriodStatusValueChange}
                        onProtectionStatusValueChange={
                            onProtectionStatusValueChange
                        }
                        onReportingPeriodChange={onReportingPeriodChange}
                        onHandleReset={onHandleReset}
                        enableReset={enableReset}
                        enableApply={enableApply}
                        onHandleOutsideClick={onHandleOutsideClick}
                        disableConfirm={disableConfirm}
                    />
                </div>
            </section>
        </>
    );
}

export default ReportViewFilter;
