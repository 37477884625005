import React from "react";

export default function PreferenceSaveNotification({ handleSavedPreference }) {
    return (
        <div className="d-flex  justify-content-end preference-saved-popup-wrapper">
            <div className="section-card preference-saved-popup position-absolute m-3">
                <div className="mt-20">
                    <i
                        className="fa-solid fa-circle-check"
                        style={{
                            color: "#5E9E42",
                            marginRight: 8,
                        }}
                    ></i>
                    <label className="pb-10p">Preference saved </label>
                </div>
                <div>
                    <i
                        className="fa-solid fa-circle-xmark cursor-pointer"
                        style={{
                            color: "#5E9E42",
                        }}
                        onClick={handleSavedPreference}
                        onKeyDown={(event) => {
                            if (event.key == 13) {
                                handleSavedPreference();
                            }
                        }}
                        tabIndex={0}
                    ></i>
                </div>
            </div>
        </div>
    );
}
