import React, { useState, useCallback } from "react";
import { Breadcrumb } from "../../../components/ui/Breadcrumb";
import { Accordion, Card } from "react-bootstrap";
import AccordionToggle from "../../../components/AccordionToggle.js";
import UserGuide from "./UserGuide";
import DiagnosticCategories from "./DiagnosticCategories";
import ClaimStatusReason from "./ClaimStatusReason";

const ReferenceResources = () => {
    const [activeAccordion, setActiveAccordion] = useState("");

    const handleAccordionToggle = useCallback((eventKey) => {
        setActiveAccordion((prevAccordion) =>
            prevAccordion === eventKey ? "" : eventKey
        );
    }, []);

    const renderAccordion = (eventKey, title, content) => (
        <div
            className="section-card reference-card"
            style={{ paddingBottom: activeAccordion === eventKey ? 0 : 24 }}
        >
            <div>
                <Accordion
                    activeKey={activeAccordion}
                    onSelect={handleAccordionToggle}
                >
                    <AccordionToggle
                        eventKey={eventKey}
                        className="justify-content-between mb-0p"
                    >
                        <div className="reference-header">{title}</div>
                    </AccordionToggle>
                    <Accordion.Collapse
                        eventKey={eventKey}
                        className="reference-content"
                    >
                        <Card.Body>{content}</Card.Body>
                    </Accordion.Collapse>
                </Accordion>
            </div>
        </div>
    );

    return (
        <div>
            <Breadcrumb
                links={[
                    {
                        path: "/search",
                        name: "Search",
                    },
                    {
                        path: "/help",
                        name: "Help",
                    },
                    {
                        name: "Reference Resources",
                    },
                ]}
            />
            <div className="faq-content">
                {renderAccordion(
                    "accordion-1",
                    "Claim Status Reasons",
                    <ClaimStatusReason />
                )}
                {renderAccordion(
                    "accordion-2",
                    "Diagnostic Categories",
                    <DiagnosticCategories />
                )}
                {renderAccordion("accordion-3", "User Guide", <UserGuide />)}
            </div>
        </div>
    );
};

export default ReferenceResources;
