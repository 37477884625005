import React, { useState, useCallback } from "react";
import { Breadcrumb } from "../../../components/ui/Breadcrumb";
import { Accordion, Card } from "react-bootstrap";
import AccordionToggle from "../../../components/AccordionToggle.js";
import Divider from "../../../components/ui/Divider";
import faqData from "./faqContent.json";

function FrequentlyAskedQuestions() {
    const [activeAccordion, setActiveAccordion] = useState(null);

    const handleAccordionToggle = useCallback((index) => {
        setActiveAccordion((prevState) => (index === prevState ? null : index));
    }, []);

    return (
        <div>
            <Breadcrumb
                links={[
                    {
                        path: "/search",
                        name: "Search",
                    },
                    {
                        path: "/help",
                        name: "Help",
                    },
                    {
                        name: "Frequently Asked Questions (FAQ)",
                    },
                ]}
            />

            <div className="faq-content">
                {faqData.map((group) => (
                    <div className="section-card" key={group.type}>
                        <span className="section-heading text-bold d-flex pb-16p">
                            {group.name}
                        </span>

                        {group.content.map((header) => {
                            const isAccordionActive =
                                header.id === activeAccordion;
                            return (
                                <div key={header.id}>
                                    <Accordion
                                        activeKey={
                                            isAccordionActive ? "0" : null
                                        }
                                        onSelect={() =>
                                            handleAccordionToggle(header.id)
                                        }
                                    >
                                        <AccordionToggle
                                            eventKey="0"
                                            className="faq-subtext"
                                        >
                                            <div className="faq-header">
                                                {header.header}
                                            </div>
                                        </AccordionToggle>

                                        <Accordion.Collapse
                                            eventKey="0"
                                            className="faq-subtext-collapse"
                                        >
                                            <Card.Body className="faq-card">
                                                {typeof header.description ===
                                                "string" ? (
                                                    <div>
                                                        {header.description}
                                                    </div>
                                                ) : (
                                                    <ul className="faq-ul">
                                                        {Object.values(
                                                            header.description
                                                        ).map((point) => (
                                                            <li
                                                                key={point.id}
                                                                className="faq-list"
                                                            >
                                                                {Object.values(
                                                                    point
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Accordion>
                                    {header.lineBreak === true && (        
                                        <Divider className=" faq-divider" />
                                    )}
                                </div>
                            );
                        })}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FrequentlyAskedQuestions;
