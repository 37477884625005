import React, {
    useDeferredValue,
    useState,
    useRef,
    useEffect,
    useCallback,
} from "react";
import NoRecords from "../../components/alerts/NoRecords";
import OrganizationAutocompleteDropdown from "../../components/OrganizationAutocompleteDropdown";
import Button from "../../components/ui/Button";
import OrganizationResult from "./OrganizationResult";
import Select from "react-select";
import { removeLeadingZeros } from "../../utils/helpers";
import OrganizationRepository from "../../repositories/OrganizationRepository";
import {
    addLastSelectedOrganization,
    addSelectedOrganization,
} from "../../slices/organizationSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GTM from "../../GTM";
import InsightInfo from "../../components/InsightInfo";

const list = [
    { value: "party/policy", label: "Party ID / Policy Number" },
    { value: "organizationName", label: "Organization" },
];

function OrganizationSearch({ organizations, policies }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const gtm = new GTM(null);

    const [searchType, setSearchType] = useState(list[1]);
    const [searchValue, setSearchValue] = useState("");
    const deferredSearchValue = useDeferredValue(searchValue);

    const { organizations: allOrganizations } = useSelector(
        (state) => state.userDetails
    );
    const { organizationPreference } = useSelector(
        (state) => state.preferenceSlice
    );
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);
    const [isFiltered, setIsFiltered] = useState(false);
    const [dropdownOrganizations, setDropdownOrganizations] = useState([]);
    const [filterTimestamp, setFilterTimestamp] = useState(null);

    const organizationInputRef = useRef();

    const gtmEvent = (category, action, label, attribute3, attribute4) => {
        gtm.Event(
            "search",
            category,
            action,
            label,
            "search",
            "",
            attribute3,
            attribute4
        );
    };

    const submitForm = (e) => {
        e.preventDefault();

        setIsFiltered(true);
        setDropdownOrganizations([]);
        setFilterTimestamp(new Date().getTime());

        gtmEvent("button", "click", "search", "field_interaction", searchType.label.toLowerCase());

        if (searchType?.value === "organizationName") {
            const filtered = organizations.filter((item) => {
                return item.ORGNIZATIONNAME.toLowerCase().startsWith(
                    searchValue.toLowerCase()
                );
            });

            const sortedOption = filtered.sort((a, b) => {
                return a.ORGNIZATIONNAME > b.ORGNIZATIONNAME ? 1 : -1;
            });

            setFilteredOrganizations(sortedOption);
        } else {
            const policy = policies.filter(
                (p) => p.policyId === removeLeadingZeros(searchValue)
            );
            if (policy) {
                const organization = policy.map((policy) =>
                    organizations.find(
                        (o) => o.ORGANIZATIONID === policy.organizationId
                    )
                );

                if (!organization) {
                    setFilteredOrganizations([]);
                    return;
                }

                const sortedOrganizations = organization.sort((a, b) => {
                    return a.ORGNIZATIONNAME > b.ORGNIZATIONNAME ? 1 : -1;
                });

                setFilteredOrganizations(sortedOrganizations);
            } else {
                setFilteredOrganizations([]);
            }
        }
    };

    const resetForm = () => {
        setSearchType(list[1]);
        setSearchValue("");
        setDropdownOrganizations([]);
        setFilteredOrganizations([]);
        setIsFiltered(false);
        gtmEvent("button", "click", "clear", "field_interaction", "");
    };

    const handleSearchTypeUpdate = (type) => {
        setSearchType(type);
        setSearchValue("");
        gtmEvent(
            "dropdown",
            "select",
            "select organization / policy",
            "field_interaction",
            `${type.label.toLocaleLowerCase()}`
        );
    };

    const handleSearchValueChange = ({ target: input }) => {
        setSearchValue(input.value);
    };

    const handleTextboxFocus = () => {
        let label = "";
        if (searchType.value === "organizationName") {
            label = "Enter Organization";
        } else {
            label = "Enter Party ID / Policy Number";
        }
        
    };

    const handleTextboxBlurFocus = () => {
        gtmEvent("textbox", "interact", searchType.label.toLowerCase(), "field_interaction", );
   };

    const getButtonDisabledStatus = () => {
        return searchValue.trim() === "";
    };

    useEffect(() => {
        if (searchType?.value === "organizationName") {
            if (deferredSearchValue === "") {
                setDropdownOrganizations([]);
                return;
            }

            const filtered = organizations.filter((organization) => {
                return organization.ORGNIZATIONNAME.toLowerCase().startsWith(
                    deferredSearchValue.toLowerCase()
                );
            });
            const sortedOption = filtered.sort((a, b) => {
                return a.ORGNIZATIONNAME > b.ORGNIZATIONNAME ? 1 : -1;
            });

            setDropdownOrganizations(sortedOption);
        }
    }, [deferredSearchValue]);

    // organization saved in preferene
    useEffect(() => {
        if (organizationPreference && organizationPreference !== undefined) {
            const preferenceStoredOrg =
                OrganizationRepository.getRelatedOrganizationsFromIds(
                    allOrganizations,
                    organizationPreference
                );

            if (preferenceStoredOrg.length > 0) {
                dispatch(
                    addSelectedOrganization({
                        id: preferenceStoredOrg[0].ORGANIZATIONID,
                        organization: preferenceStoredOrg[0],
                        related:
                            preferenceStoredOrg[0]?.ORGANIZATIONGROUPID !== null
                                ? preferenceStoredOrg
                                : [],
                    })
                );

                dispatch(addLastSelectedOrganization(preferenceStoredOrg[0]));

                navigate(
                    `/organization/${preferenceStoredOrg[0].ORGANIZATIONID}/employees/search`,
                    { replace: true }
                );
            }
        }
    }, [organizationPreference]);

    const selectOrganization = useCallback((organization) => {
        const related = OrganizationRepository.getRelatedOrganizations(
            allOrganizations,
            organization
        );

        dispatch(
            addSelectedOrganization({
                id: organization.ORGANIZATIONID,
                organization: organization,
                related,
            })
        );

        dispatch(addLastSelectedOrganization(organization));

        navigate(
            `/organization/${organization.ORGANIZATIONID}/employees/search`
        );
    }, []);

    return (
        <div>
            <InsightInfo />

            <form onSubmit={submitForm} className="md-px-4p">
                <div
                    className=" section-card bg-none row align-items-end"
                    style={{ margin: 0 }}
                >
                    <div
                        className="col-lg-4 col-md-4 col-sm-6 md-mb-20p "
                        style={{ paddingLeft: 0 }}
                    >
                        <div className="select-search">
                            <label htmlFor="orgpolicy">
                                Select Organization / Policy
                            </label>

                            <Select
                                className="select-dropdown"
                                placeholder="Please Select"
                                value={searchType}
                                options={list}
                                onChange={handleSearchTypeUpdate}
                                isSearchable={false}
                                id="orgpolicy"
                            />
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-6 md-mb-20p">
                        <div className="position-relative">
                            {searchType.value === "organizationName" ? (
                                <>
                                    <div ref={organizationInputRef}>
                                        <label htmlFor="org">
                                            Enter Organization
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            value={searchValue}
                                            onChange={handleSearchValueChange}
                                            onKeyUp={(event) => {
                                                if (event.key == "ArrowDown")
                                                    if (
                                                        document.getElementsByClassName(
                                                            "organisationclass"
                                                        ).length > 0
                                                    )
                                                        document
                                                            .getElementsByClassName(
                                                                "organisationclass"
                                                            )[0]
                                                            .focus();
                                            }}
                                            ref={(input) =>
                                                input && input.focus()
                                            }
                                            autoComplete="off"
                                            id="org"
                                            onFocus={handleTextboxFocus}
                                            onBlur={handleTextboxBlurFocus}
                                        />
                                    </div>

                                    {dropdownOrganizations.length > 0 && (
                                        <OrganizationAutocompleteDropdown
                                            organizations={
                                                dropdownOrganizations
                                            }
                                            hideOrganizationAutocompleteDropdown={() =>
                                                setDropdownOrganizations([])
                                            }
                                            organizationInputRef={
                                                organizationInputRef
                                            }
                                            selectOrganization={
                                                selectOrganization
                                            }
                                        />
                                    )}
                                </>
                            ) : (
                                <div>
                                    <label htmlFor="policy">
                                        Enter Party ID / Policy Number
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={searchValue}
                                        onChange={handleSearchValueChange}
                                        id="policy"
                                        onFocus={handleTextboxFocus}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-6 col-6">
                        <Button
                            onClick={submitForm}
                            disabled={getButtonDisabledStatus()}
                            text="Search"
                            icon="fa-magnifying-glass"
                            type="submit"
                        />
                    </div>

                    <div
                        className="col-lg-2 col-md-2 col-sm-6 col-6 pr-0p"
                        style={{ paddingRight: 0 }}
                    >
                        <Button
                            onClick={resetForm}
                            disabled={getButtonDisabledStatus()}
                            text="Clear"
                            icon="fa-xmark"
                            outline={true}
                        />
                    </div>
                </div>
            </form>

            <div className="my-30p">
                {isFiltered &&
                    (filteredOrganizations.length === 0 ? (
                        <NoRecords
                            timestamp={filterTimestamp}
                            page="search"
                            label="search"
                            attribute1="search"
                            attribute3="search_error"
                        />
                    ) : (
                        <OrganizationResult
                            organizations={filteredOrganizations}
                            selectOrganization={selectOrganization}
                        />
                    ))}
            </div>
        </div>
    );
}

export default OrganizationSearch;
