import React from "react";

function FooterNote({ date }) {
    return (
        <div className="report-disclaimer" tabIndex={0}>
            <div className="disclaimer-date-time">Run as of: {date}</div>

            <span className="d-block fs-xs">
                It is imperative that recipients of these reports recognize the
                sensitive nature of the detail they contain and handle them with
                the same discretion as other confidential human resource
                documents.
            </span>
        </div>
    );
}

export default FooterNote;
