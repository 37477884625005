import React, { useState, useEffect } from "react";
import Select from "react-select";
import Button from "../../components/ui/Button";
import { useSelector } from "react-redux";

const HomeSearchPreferences = ({ onHomeScreenPreferenceSelected }) => {
    const { homePreference } = useSelector((state) => state.preferenceSlice);
    const filterOptions = [
        { label: "Search", value: "search" },
        {
            label: "Reports",
            value: "reportingcapabilities",
        },
    ];

    const defaultValue = homePreference?.toString();

    const [selectedOption, setSelectedOption] = useState([]);

    useEffect(() => {
        const defaultOption = filterOptions.find(
            (opt) => opt.value === defaultValue || opt.label === defaultValue
        );
        if (defaultOption) {
            setSelectedOption(defaultOption);
            onHomeScreenPreferenceSelected(defaultOption.value);
        }
    }, [homePreference]);

    const handleSelectedOptionChange = (selected) => {
        setSelectedOption(selected);
        onHomeScreenPreferenceSelected(selected.value);
    };

    const clearPreference = () => {
        setSelectedOption([]);
        onHomeScreenPreferenceSelected([]);
    };
    const getButtonDisabledStatus = () => {
        return selectedOption.length === 0;
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected & "black",
            backgroundColor: state.isSelected && "",
            margin: "0px",

            "&:hover": {
                backgroundColor: "#037CB7",
                color: "#FFFFFF",
                cursor: "pointer",
            },
        }),
    };

    return (
        <div>
            <label>Set home screen preference</label>
            <div className="d-flex" style={{ gap: 16 }}>
                <div className="col-lg-3 col-md-4 col-sm-6">
                    <Select
                        className="employeesearchpreference"
                        options={filterOptions}
                        value={selectedOption}
                        placeholder={
                            selectedOption.length > 0
                                ? selectedOption
                                : "Select..."
                        }
                        name="dropdownValue"
                        isSearchable={false}
                        onChange={handleSelectedOptionChange}
                        tabIndex="0"
                        styles={customStyles}
                    />
                </div>
                <div className="d-flex col-lg-2 col-sm-4 col-4 sm-mb-20p px-0p">
                    <Button
                        onClick={clearPreference}
                        disabled={getButtonDisabledStatus()}
                        text="Clear Preference"
                    />
                </div>
            </div>
        </div>
    );
};

export default HomeSearchPreferences;
