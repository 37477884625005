import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
    absenceCoverage,
    capitalizeFirstLetter,
    CUSTOM_DATE,
    DATAVIEWTYPE,
    disabilityCoverage,
    disabilityStatus,
    getPresentDayFormat,
    getPreviousDays,
    getPreviousDaysEnd,
    getPreviousDaysStart,
    getPreviousDaysWithTimeFormat,
    getSelectedIsfOrganizationIds,
    INCLUDE_HISTORICAL_LEAVE,
    LAST_7_DAYS,
    leaveCoverage,
    lifeCoverage,
    paymentCoverage,
    paymentStatus,
    PERIOD_SCHEDULE_TYPE,
    PERIOD_STATUS,
    PREVIOUS_DAY,
    PREVIOUS_MONTH,
    PREVIOUS_QUARTER,
    PROTECTION_STATUS,
    STANDARD_REPORT_DATE_TIME,
    LAST_7_DAYS_LABEL,
} from "../../utils/helpers";
import ReportViewFilter from "./ReportViewFilter";
import ReportViewData from "./ReportViewData";
import {
    useSaveReportViewMutation,
    useLazyGetReportDetailsQuery,
    useUpdateReportDetailsMutation,
    useUpdateFrequentlyUsedReportsMutation,
} from "../../slices/saveReportViewSlice";
import {
    useLazyGetClaimsSearchQuery,
    useLazyGetClaimsPaymentSearchQuery,
    useLazyGetLeavesSearchQuery,
} from "../../slices/dataviewApiSlice";
import ReportSaveNotification from "./ReportSaveNotification";
import ReportSaveErrorNotification from "./ReportSaveErrorNotification";
import FooterNote from "../claimleaveview/FooterNote";
import moment from "moment";
import GTM from "../../GTM";
import AbsenceSearch from "../../models/AbsenceSearch";
import { clearDlUrlValues } from "../../slices/authSlice";

function ReportViewPage(props) {
    const { user } = useSelector((state) => state.auth);
    const { config } = useSelector((state) => state.app);
    let previousUrls = props.previousUrl;
    let page_referrer = previousUrls[previousUrls.length - 1];
    let mmDateFormat = "MM/DD/YYYY";
    const gtm = new GTM(null);
    const { viewtype } = useParams();
    let reportViewType = capitalizeFirstLetter(viewtype);
    const paymentHistoryCard = "PaymentHistory";
    const disabilityCard = "Disability";
    const leaveCard = "Leave";
    const absenceCard = "Absence";
    const lifeCard = "Life";

    // Function to determine page_referrer_title based on page_referrer_url
    const getPageReferrerTitle = (page_referrer_url) => {
        return page_referrer_url?.includes("/reportingcapabilities")
            ? "run reports"
            : undefined;
    };

    const page_referrer_title = getPageReferrerTitle(page_referrer);

    useEffect(() => {
        const userName = "";
        const userId = user ? user.userId : "";
        const userRole = user.isUserInternal ? "internal" : "external";

        gtm.PageView(
            `reports ${reportViewType}`,
            userName,
            userId,
            userRole,
            window.location.href,
            `${config.REACT_APP_BASE_URL}${page_referrer}`,
            page_referrer_title,
            ""
        );
    }, [20000]);
    // api call to get report data
    const [
        getClaimsSearch,
        { data: reportTableData = [], isFetching, isUninitialized, isError },
    ] = useLazyGetClaimsSearchQuery();

    const [
        getClaimsPaymentSearch,
        {
            data: reportPaymentTableData = [],
            isFetching: isFetchingPayment,
            isUninitialized: isUninitializedPayment,
            isError: isErrorPayment,
        },
    ] = useLazyGetClaimsPaymentSearchQuery();

    const [
        getLeavesSearch,
        {
            data: reportLeaveTableData = [],
            isFetching: isFetchingLeave,
            isUninitialized: isUninitializedLeave,
            isError: isErrorLeave,
        },
    ] = useLazyGetLeavesSearchQuery();

    const {
        selectedReport,
        selectedReportTypeData: reportData,
        title,
        portalUserData,
        isError: portalUserApiError,
    } = useLocation().state || {};
    const [saveReportViewMutation, { data: savedReportViewId }] =
        useSaveReportViewMutation();

    const [updateFrequentlyUsedReportsViewId] =
        useUpdateFrequentlyUsedReportsMutation();
    const [getReportDetails, { data: savedReportData }] =
        useLazyGetReportDetailsQuery({});
    const [selectedFilters, setSelectedFilters] = useState();
    const [reportingPeriodValue, setReportingPeriodValue] = useState({
        value: LAST_7_DAYS,
        label: LAST_7_DAYS_LABEL,
    });
    const [absenceViewData, setAbsenceViewData] = useState([]);

    const [includeHistoricalLeaveValue, setIncludeHistoricalLeaveValue] =
        useState(INCLUDE_HISTORICAL_LEAVE[1]);
    const [periodScheduleTypeValue, setPeriodScheduleTypeValue] =
        useState(PERIOD_SCHEDULE_TYPE);
    const [periodStatusValue, setPeriodStatusValue] = useState(PERIOD_STATUS);
    const [protectionStatusValue, setProtectionStatusValue] =
        useState(PROTECTION_STATUS);

    const fiveYearsAgo = new Date();
    fiveYearsAgo.setFullYear(fiveYearsAgo.getFullYear() - 5);

    const [customDateRange, setCustomDateRange] = useState([
        {
            startDate: null,
            endDate: null,
            key: "selection",
            label: "Custom Range",
            selectedFromDate: null,
            selectedEndDate: null,
        },
    ]);
    const [selectionMode, setSelectionMode] = useState("start"); // Track if we are selecting start or end date

    const getCamelCasedReportViewData = (data) => {
        return data.map((obj1) => ({
            DataViewType: obj1.DataViewType,
            Description: obj1.Description,
            MostRecentOrder: obj1.MostRecentOrder,
            MostUsedOrder: obj1.MostUsedOrder,
            Name: obj1.Name,
            OwnerType: obj1.OwnerType,
            CoverageType: obj1.CoverageType,
            ViewId: obj1.ViewId,
            Status: obj1.Status,
            EffectiveStartDate: obj1.EffectiveStartDate,
            EffectiveEndDate: obj1.EffectiveEndDate,
            ColumnInformation: obj1.ColumnInformation.map((obj) => ({
                accessorKey: obj.AccessorKey,
                checked: obj.Checked,
                enableHiding: obj.LockedDisplay,
                header: obj.Header,
                id: obj.Id,
                cell: (info) => info.getValue(),
                size: obj.Size,
                minSize: obj.MinSize,
                maxSize: obj.MaxSize,
            })),
            PeriodScheduleTypes: obj1.PeriodScheduleTypes,
            IncludeHistoricalLeave: obj1.IncludeHistoricalLeave,
            PeriodStatuses: obj1.PeriodStatuses,
            ProtectionStatuses: obj1.ProtectionStatuses,
        }));
    };
    const modifiedObjectFields = getCamelCasedReportViewData(reportData);
    let organizationIDISF = "";

    let allowedPoliciesAndDivisions = {};

    const [selectedViewData, setSelectedViewData] =
        useState(modifiedObjectFields);
    const {
        selectedOrganizationsReports: userSelectedOrganizationReports,
        selectedOrganizationsIdReports: reportId,
        selectedRelatedOrganizationsForReports: selectedRelatedOrganizations,
    } = useSelector((state) => state.reportOrganization);

    // get ssn access for selected organization
    const { organizationsAccess } = useSelector((state) => state.organizations);

    const [reportSaving, setReportSaving] = useState(false);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [selectedFilterStatus, setSelectedFilterStatus] = useState(true);
    const [allViewsData, setAllViewsData] = useState(modifiedObjectFields);
    const [updateReportViewMutation] = useUpdateReportDetailsMutation();
    const [showReportSaveNotification, setShowReportSaveNotification] =
        useState(false);
    const [
        showReportSaveErrorNotification,
        setShowReportSaveErrorNotification,
    ] = useState(false);
    const [notificationMessageIndicator, setNotificationMessageIndicator] =
        useState();
    const [reportLastFetched, setreportLastFetched] = useState();
    const [coverageType, setCoverageType] = useState();
    const [reportUpdated, setReportUpdated] = useState(false);
    const [enableApply, setEnableApply] = useState(false);
    const [enableReset, setEnableReset] = useState(false);
    const [disableConfirm, setDisableConfirm] = useState(false);
    const coverageTypeOptions = (
        (typeof coverageType === "string"
            ? coverageType.split(",")
            : coverageType) || []
    )?.map((code) => ({
        value: code,
        label: code,
    }));
    const status =
        reportViewType === paymentHistoryCard
            ? paymentStatus
            : disabilityStatus;
    let coverage;

    if (reportViewType === paymentHistoryCard) {
        coverage = paymentCoverage;
    } else if (reportViewType === disabilityCard) {
        coverage = disabilityCoverage;
    } else if (reportViewType === absenceCard) {
        coverage = absenceCoverage;
    } else if (reportViewType === lifeCard) {
        coverage = lifeCoverage;
    } else if (reportViewType === leaveCard) {
        coverage = leaveCoverage;
    }
    if (
        portalUserData &&
        portalUserData.assignedOrgs &&
        Array.isArray(portalUserData.assignedOrgs)
    ) {
        const fullListOfPoliciesAndDivisions = {};

        portalUserData.assignedOrgs.forEach((assignedOrg) => {
            assignedOrg.assignedPolicies.forEach((policy) => {
                const { policyId, divisionId, divisionName } = policy;

                if (!fullListOfPoliciesAndDivisions[policyId]) {
                    fullListOfPoliciesAndDivisions[policyId] = {
                        policyId,
                        divisions: [],
                    };
                }

                fullListOfPoliciesAndDivisions[policyId].divisions.push({
                    divisionId,
                    divisionName,
                });
            });
        });

        allowedPoliciesAndDivisions = Object.values(
            fullListOfPoliciesAndDivisions
        );
    } else {
        allowedPoliciesAndDivisions = [];
    }

    const initialPolicyDivision = allowedPoliciesAndDivisions.flatMap(
        (item) => [
            item.policyId,
            ...(Array.isArray(item.divisions)
                ? item.divisions.map(
                      (division) => `${item.policyId}-${division.divisionId}`
                  )
                : []),
        ]
    );

    // filter values
    const [selectedCoverageType, setSelectedCoverageType] = useState(coverage);
    const [reportingStatusValue, setReportingStatusValue] = useState(status);
    const [selectedPolicyDivision, setSelectedPolicyDivision] = useState(
        initialPolicyDivision
    );
    const handlePolicyDivision = (policy) => {
        setSelectedPolicyDivision(policy);
        setEnableReset(true);
        setEnableApply(true);
    };

    const {
        selectedIsfOrganizationIds,
        organizationGroupId,
        relatedOrganizationNames,
        organizationName,
    } = getSelectedIsfOrganizationIds(
        userSelectedOrganizationReports,
        reportId,
        selectedRelatedOrganizations
    );

    const handleViewOptionChange = (item) => {
        setEnableReset(false);
        setEnableApply(false);
        let findSelectedViewDetails = "";
        if (savedReportData) {
            findSelectedViewDetails =
                getCamelCasedReportViewData(savedReportData);
            setSelectedViewData([
                findSelectedViewDetails.find((obj) => obj.Name === item.value),
            ]);
            const getSelectedView = findSelectedViewDetails.find(
                (obj) => obj.Name === item.value
            );
            setCustomDateRange([
                {
                    startDate: moment(
                        getSelectedView.EffectiveStartDate.substring(0, 10)
                    ).format(mmDateFormat),
                    endDate: moment(
                        getSelectedView.EffectiveEndDate.substring(0, 10)
                    ).format(mmDateFormat),
                    key: "selection",
                    selectedFromDate: moment(
                        getSelectedView.EffectiveStartDate.substring(0, 10)
                    ).format(mmDateFormat),
                    selectedEndDate: moment(
                        getSelectedView.EffectiveEndDate.substring(0, 10)
                    ).format(mmDateFormat),
                },
            ]);
            setDisableConfirm(true);
            const selectedReportTypeData = savedReportData
                .filter((obj) =>
                    obj.DataViewType.includes(reportViewType.slice(0, 4))
                )
                .map((obj) => obj);
            setAllViewsData(
                getCamelCasedReportViewData(selectedReportTypeData)
            );
        } else {
            findSelectedViewDetails = modifiedObjectFields.find(
                (obj) => obj.Name === item.value
            );
            setSelectedViewData([findSelectedViewDetails]);
            let startDate = findSelectedViewDetails.EffectiveStartDate;
            let endDate = findSelectedViewDetails.EffectiveEndDate;
            if (
                startDate === STANDARD_REPORT_DATE_TIME ||
                endDate === STANDARD_REPORT_DATE_TIME
            ) {
                handleDateRangeChange({
                    reset: true,
                });
            } else {
                setCustomDateRange([
                    {
                        startDate: startDate.substring(0, 10),
                        endDate: endDate.substring(0, 10),
                        key: "selection",
                        selectedFromDate: startDate.substring(0, 10),
                        selectedEndDate: endDate.substring(0, 10),
                    },
                ]);
                setDisableConfirm(true);
            }
        }
    };

    useEffect(() => {
        if (reportUpdated) {
            const selectedReportTypeData = savedReportData
                .filter((obj) =>
                    obj.DataViewType.includes(reportViewType.slice(0, 4))
                )
                .map((obj) => obj);
            const updatedSelectedView = selectedReportTypeData.find(
                (obj) => obj.ViewId === selectedViewData[0].ViewId
            );
            const modifiedSelectedViewData = {
                DataViewType: updatedSelectedView.DataViewType,
                Description: updatedSelectedView.Description,
                MostRecentOrder: updatedSelectedView.MostRecentOrder,
                MostUsedOrder: updatedSelectedView.MostUsedOrder,
                Name: updatedSelectedView.Name,
                OwnerType: updatedSelectedView.OwnerType,
                CoverageType: updatedSelectedView.CoverageType,
                ViewId: updatedSelectedView.ViewId,
                Status: updatedSelectedView.Status,
                EffectiveStartDate: updatedSelectedView.EffectiveStartDate,
                EffectiveEndDate: updatedSelectedView.EffectiveEndDate,
                ColumnInformation: updatedSelectedView.ColumnInformation.map(
                    (obj) => ({
                        accessorKey: obj.AccessorKey,
                        checked: obj.Checked,
                        enableHiding: obj.LockedDisplay,
                        header: obj.Header,
                        id: obj.Id,
                        cell: (info) => info.getValue(),
                        size: obj.Size,
                        minSize: obj.MinSize,
                        maxSize: obj.MaxSize,
                    })
                ),
                PeriodScheduleTypes: updatedSelectedView.PeriodScheduleTypes,
                IncludeHistoricalLeave:
                    updatedSelectedView.IncludeHistoricalLeave,
            };
            setSelectedViewData([modifiedSelectedViewData]);
            setAllViewsData(
                getCamelCasedReportViewData(selectedReportTypeData)
            );
        }
    }, [reportUpdated, reportViewType, savedReportData]);

    useEffect(() => {
        if (reportSaving === true) {
            setReportSaving(false);
            if (savedReportData.length) {
                const modifiedObjectFields =
                    getCamelCasedReportViewData(savedReportData);
                const selectedReportTypeData = modifiedObjectFields
                    .filter((obj) =>
                        obj.DataViewType.includes(reportViewType.slice(0, 4))
                    )
                    .map((obj) => obj);
                setAllViewsData(selectedReportTypeData);
                setSelectedViewData([
                    selectedReportTypeData.find(
                        (obj) => obj.ViewId === savedReportViewId.ViewId
                    ),
                ]);
            }
        }
    }, [reportSaving]);

    useEffect(() => {
        let findSelectedViewDetails = "";
        if (selectedReport && selectedReport !== undefined) {
            findSelectedViewDetails = modifiedObjectFields.find(
                (obj) => obj.ViewId === selectedReport.ViewId
            );
            setSelectedViewData([findSelectedViewDetails]);
        }
    }, [selectedReport]);

    const reportDetailsParams = {
        isfUserId: user.userId,
        dataViewType: DATAVIEWTYPE,
        selectedIsfOrganizationIds: selectedIsfOrganizationIds,
    };

    // Include organizationGroupId if it's defined
    if (organizationGroupId) {
        reportDetailsParams.organizationGroupId = organizationGroupId;
    }
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(clearDlUrlValues());
    }, []);

    const handleViewReportSave = (
        changedColumnSorting,
        changedColumnOrder,
        viewName,
        viewDescription
    ) => {
        setLoaderStatus(true);
        if (reportViewType === "PaymentHistory") {
            reportViewType = "Payments";
        }
        saveReportViewMutation({
            dataToUpdate: {
                IsfUserId: user.userId,
                OwnerType: "Personal",
                SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                DataViewType: reportViewType,

                DataViewInfo: {
                    Name: viewName,
                    Description: viewDescription,
                    Parameters: {
                        CoverageType: selectedCoverageType.map(
                            (item) => item.value
                        ),
                        EffectiveEndDt: selectedFilters?.effectiveEndDate
                            ? moment(
                                  selectedFilters?.effectiveEndDate
                              ).toISOString()
                            : getPreviousDaysWithTimeFormat(
                                  reportLastFetched,
                                  1
                              ),
                        EffectiveStartDt: selectedFilters?.effectiveStartDate
                            ? moment(
                                  selectedFilters?.effectiveStartDate
                              ).toISOString()
                            : getPreviousDaysWithTimeFormat(
                                  reportLastFetched,
                                  7
                              ),
                        GroupAgreementDivisions: null,
                        IncludeHistoricalLeave:
                            includeHistoricalLeaveValue.value === "No"
                                ? false
                                : true,
                        PaymentStatuses: null,
                        PeriodScheduleTypes: periodScheduleTypeValue?.map(
                            (item) => item.value
                        ),
                        PeriodStatuses: periodStatusValue?.map(
                            (item) => item.value
                        ),
                        ProductGroupCds: ["STD", "SI-STD"],
                        ProtectionStatuses: protectionStatusValue?.map(
                            (item) => item.value
                        ),
                        ReportingGroups: null,
                        ReportingPeriodMethod: null,
                        Status: reportingStatusValue
                            ?.map((item) => item.value)
                            .toString(),
                    },
                    TableDisplayInformation: {
                        SortOrder:
                            changedColumnSorting[0] === undefined
                                ? null
                                : changedColumnSorting[0],
                        ColumnInformation: changedColumnOrder,
                    },
                },
            },
        })
            .unwrap()
            .then(() => {
                getReportDetails({
                    isfUserId: user.userId,
                    selectedIsfOrganizationIds: selectedIsfOrganizationIds,
                    dataViewType: DATAVIEWTYPE,
                }).then(() => {
                    setReportSaving(true);
                    setLoaderStatus(false);
                    setSelectedFilterStatus(true);
                    setShowReportSaveNotification(true);
                    setTimeout(() => {
                        setShowReportSaveNotification(false);
                    }, 6000);
                });
            })
            .catch((e) => {
                setLoaderStatus(false);
                setShowReportSaveErrorNotification(true);
                setTimeout(() => {
                    setShowReportSaveErrorNotification(false);
                }, 6000);
            });
    };

    const handleViewReportUpdate = (
        changedColumnSorting,
        changedColumnOrder,
        updateViewDetails,
        viewName,
        viewDescription
    ) => {
        setLoaderStatus(true);
        updateReportViewMutation({
            dataToUpdate: {
                IsfUserId: user.userId,
                OwnerType: "Personal",
                SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                DataViewType: updateViewDetails.DataViewType,
                IsEnabled: true,
                DataViewInfo: {
                    Name: viewName,
                    Description: viewDescription,
                    Parameters: {
                        CoverageType: selectedCoverageType.map(
                            (item) => item.value
                        ),
                        EffectiveEndDt: selectedFilters?.effectiveEndDate
                            ? moment(
                                  selectedFilters?.effectiveEndDate
                              ).toISOString()
                            : getPreviousDaysWithTimeFormat(
                                  reportLastFetched,
                                  1
                              ),
                        EffectiveStartDt: selectedFilters?.effectiveStartDate
                            ? moment(
                                  selectedFilters?.effectiveStartDate
                              ).toISOString()
                            : getPreviousDaysWithTimeFormat(
                                  reportLastFetched,
                                  7
                              ),
                        GroupAgreementDivisions: null,
                        IncludeHistoricalLeave: false,
                        PaymentStatuses: null,
                        ProductGroupCds: ["STD", "SI-STD"],
                        PeriodScheduleTypes: periodScheduleTypeValue?.map(
                            (item) => item.value
                        ),
                        PeriodStatuses: periodStatusValue?.map(
                            (item) => item.value
                        ),
                        ProtectionStatuses: protectionStatusValue?.map(
                            (item) => item.value
                        ),
                        ReportingGroups: null,
                        ReportingPeriodMethod: null,
                        Status: reportingStatusValue
                            .map((item) => item.value)
                            .toString(),
                    },
                    TableDisplayInformation: {
                        SortOrder:
                            changedColumnSorting[0] === undefined
                                ? null
                                : changedColumnSorting[0],
                        ColumnInformation: changedColumnOrder,
                    },
                },
            },
            viewId: updateViewDetails.ViewId,
        })
            .unwrap()
            .then(() => {
                getReportDetails({
                    isfUserId: user.userId,
                    selectedIsfOrganizationIds: selectedIsfOrganizationIds,
                    dataViewType: DATAVIEWTYPE,
                }).then(() => {
                    setReportUpdated(true);
                    setLoaderStatus(false);
                    setSelectedFilterStatus(true);
                    setShowReportSaveNotification(true);
                    setTimeout(() => {
                        setShowReportSaveNotification(false);
                    }, 6000);
                });
            })
            .catch((e) => {
                setLoaderStatus(false);
                setShowReportSaveErrorNotification(true);
                setTimeout(() => {
                    setShowReportSaveErrorNotification(false);
                }, 6000);
            });
    };

    const notificationMessageUpdate = (indicator) => {
        setNotificationMessageIndicator(indicator);
    };

    const handleReportSavedNotificationClose = () => {
        setShowReportSaveNotification(false);
    };

    const handleReportErrorNotificationClose = () => {
        setShowReportSaveErrorNotification(false);
    };

    const [viewId, setViewId] = useState();

    const handleViewID = (viewId) => {
        setViewId(viewId);
    };

    useEffect(() => {
        if (viewId !== undefined && viewId !== "") {
            updateFrequentlyUsedReportsViewId({
                viewId: viewId,
                parametrs: {
                    IsfUserId: user.userId,
                    SelectedIsfOrganizationIds: selectedIsfOrganizationIds,
                },
            });
        }
    }, [viewId]);

    const handleCoverageType = (parameters) => {
        setCoverageType(parameters);
    };

    useEffect(() => {
        setreportLastFetched(moment().format("MM/DD/YYYY hh:mm A"));
    }, [isFetching, isFetchingPayment, isFetchingLeave]);

    if (reportTableData && Array.isArray(reportTableData.claimsSearch)) {
        //TODO: Leaving this around incase the team changes its mind, this can be removed once
        //portal-users is returning eligibility groups from Agreement
        let fullListOfReportingGroups = [];
        reportTableData.claimsSearch.forEach((claim) => {
            fullListOfReportingGroups.push({
                value: claim.eligibilityGroup,
                label: claim.eligibilityGroup,
            });
        });
        const uniqueReportingGroups = [
            ...new Map(
                fullListOfReportingGroups?.map((item) => [item.value, item])
            ).values(),
        ];
        const noNullsReportingGroups = uniqueReportingGroups.filter(function (
            e
        ) {
            return e.value !== null;
        });
        const sortedReportingGroups = noNullsReportingGroups.sort((a, b) =>
            a.label.localeCompare(b.label)
        );
    }

    const previousDayDate = moment(
        getPreviousDays(reportLastFetched, 1)
    ).format(mmDateFormat);
    const last7DayDate =
        moment(getPreviousDays(reportLastFetched, 7)).format(mmDateFormat) +
        " - " +
        moment(getPreviousDays(reportLastFetched, 1)).format(mmDateFormat);
    const previousMonthDate =
        moment(getPreviousDaysStart(reportLastFetched, "month")).format(
            mmDateFormat
        ) +
        " - " +
        moment(getPreviousDaysEnd(reportLastFetched, "month")).format(
            mmDateFormat
        );
    const previousQuaterDate =
        moment(getPreviousDaysStart(reportLastFetched, "quarter")).format(
            mmDateFormat
        ) +
        " - " +
        moment(getPreviousDaysEnd(reportLastFetched, "quarter")).format(
            mmDateFormat
        );

    useEffect(() => {
        if (viewtype === "PaymentHistory") {
            setReportingPeriodValue({
                value: PREVIOUS_MONTH,
                label: PREVIOUS_MONTH + previousMonthDate,
            });
        } else {
            setReportingPeriodValue({
                value: LAST_7_DAYS,
                label: LAST_7_DAYS_LABEL + last7DayDate,
            });
        }
    }, []);

    let customRangeDate = "";
    if (
        customDateRange[0].selectedFromDate !== null &&
        customDateRange[0].selectedEndDate !== null
    ) {
        customRangeDate =
            moment(customDateRange[0].selectedFromDate).format(mmDateFormat) +
            " - " +
            moment(customDateRange[0].selectedEndDate).format(mmDateFormat);
    } else {
        customRangeDate = "";
    }

    const reporting_Period = [
        { value: PREVIOUS_DAY, label: "Previous day " + previousDayDate },
        { value: LAST_7_DAYS, label: LAST_7_DAYS_LABEL + last7DayDate },
        {
            value: PREVIOUS_MONTH,
            label: PREVIOUS_MONTH + previousMonthDate,
        },
        {
            value: PREVIOUS_QUARTER,
            label: PREVIOUS_QUARTER + previousQuaterDate,
        },
        { value: CUSTOM_DATE, label: CUSTOM_DATE + customRangeDate },
    ];

    function getMappedValues(selectedStatuses) {
        let mappedValues = [];
        if (reportViewType === paymentHistoryCard) {
            selectedStatuses.forEach((status) => {
                switch (status) {
                    case "Paid":
                        mappedValues.push("Paid");
                        break;
                    case "Reversal":
                        mappedValues.push(
                            "Reversal - Complete",
                            "Reversal of advice"
                        );
                        break;
                    case "Stop":
                        mappedValues.push("Stop - Complete");
                        break;
                    case "Repayment":
                        mappedValues.push("Refund - Complete");
                        break;
                    case "Advised":
                        mappedValues.push("Advised");
                        break;
                }
            });
        } else if (reportViewType === disabilityCard || leaveCard) {
            selectedStatuses.forEach((status) => {
                switch (status) {
                    case "New":
                        mappedValues.push("New", "Pended");
                        break;
                    case "Open":
                        mappedValues.push("Open");
                        break;
                    case "Closed":
                        mappedValues.push("Closed", "Closed-Litigation");
                        break;
                }
            });
        }
        return mappedValues;
    }

    const reportingGroup = [{ value: "ALl", label: "All" }];

    const initialFiltersValue = [
        {
            coverageTypeOptions: coverageTypeOptions,
            reporting_Period: reporting_Period,
            status: status,
            PoliciesAndDivisions: allowedPoliciesAndDivisions,
        },
    ];

    const handleApplyFilter = (filterValue) => {
        setEnableApply(false);
        let reportingPeriodStartDate = null;
        let reportingPeriodEndDate = null;
        if (reportingPeriodValue.value === PREVIOUS_DAY) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 1);
            reportingPeriodEndDate = getPresentDayFormat(reportLastFetched);
        } else if (reportingPeriodValue.value === LAST_7_DAYS) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 7);
            reportingPeriodEndDate = getPreviousDays(reportLastFetched, 1);
        } else if (reportingPeriodValue.value === PREVIOUS_MONTH) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "month"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "month"
            );
        } else if (reportingPeriodValue.value === PREVIOUS_QUARTER) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "quarter"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "quarter"
            );
        }

        if (reportingPeriodValue.value === CUSTOM_DATE) {
            reportingPeriodStartDate = getPresentDayFormat(
                customDateRange[0].startDate
            );
            reportingPeriodEndDate = getPresentDayFormat(
                customDateRange[0].endDate
            );
            setDisableConfirm(true);
        }
        setSelectedFilterStatus(false);
        setReportingPeriodValue(reportingPeriodValue);

        setSelectedFilters(filterValue);

        let filters = {
            organizationId: selectedIsfOrganizationIds,
            claimCoverageTypes: selectedCoverageType.map((item) => item.value),
            claimStatus: getMappedValues(
                reportingStatusValue.map((item) => item.value)
            ),
            effectiveStartDate: reportingPeriodStartDate,
            effectiveEndDate: reportingPeriodEndDate,
            groupAgreementDivision:
                selectedPolicyDivision.length > 500
                    ? ""
                    : selectedPolicyDivision,

            startIndex: 0,
            startPage: 1,
            count: 100,
        };

        reportViewType === paymentHistoryCard
            ? (filters = {
                  ...filters,
                  paymentStatus: getMappedValues(
                      reportingStatusValue.map((item) => item.value)
                  ),
              })
            : (filters = {
                  ...filters,
                  claimStatus: getMappedValues(
                      reportingStatusValue.map((item) => item.value)
                  ),
              });

        if (reportViewType === paymentHistoryCard) {
            getClaimsPaymentSearch({
                filters: filters,
            });
        } else if (reportViewType === disabilityCard) {
            getClaimsSearch({
                filters: filters,
            });
        } else if (reportViewType === leaveCard) {
            getLeavesSearch({
                filters: {
                    organizationId: filters.organizationId,
                    coverageType: filters.claimCoverageTypes,
                    effectiveStartDate: reportingPeriodStartDate,
                    effectiveEndDate: reportingPeriodEndDate,
                    startIndex: filters.startIndex,
                    startPage: filters.startPage,
                    count: filters.count,
                    leaveStatus: reportingStatusValue.map((item) => item.value),
                    leaveScheduleType: periodScheduleTypeValue.map(
                        (item) => item.value
                    ),
                    historicalLeaveInd:
                        includeHistoricalLeaveValue.value === "No" ? "N" : "Y",
                    leavePeriodStatus: periodStatusValue.map(
                        (item) => item.value
                    ),
                    protectionStatus: protectionStatusValue.map(
                        (item) => item.value
                    ),
                },
            });
        } else if (reportViewType === absenceCard) {
            getLeavesSearch({
                filters: {
                    organizationId: filters.organizationId,
                    coverageType: filters.claimCoverageTypes.includes("Leave")
                        ? "Leave"
                        : "",
                    effectiveStartDate: reportingPeriodStartDate,
                    effectiveEndDate: reportingPeriodEndDate,
                    startIndex: filters.startIndex,
                    startPage: filters.startPage,
                    count: filters.count,
                    //leaveStatus:reportingStatusValue.map((item)=>item.value),
                    leaveScheduleType: periodScheduleTypeValue.map(
                        (item) => item.value
                    ),
                    historicalLeaveInd:
                        includeHistoricalLeaveValue.value === "No" ? "N" : "Y",
                },
            });
            getClaimsSearch({
                filters: filters,
            });
        }
    };

    useEffect(() => {
        let reportingPeriodStartDate = null;
        let reportingPeriodEndDate = null;
        handleDateRangeChange({
            reset: true,
        });
        const item = reportingPeriodValue;
        if (item.value === PREVIOUS_DAY) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 1);
            reportingPeriodEndDate = getPresentDayFormat(reportLastFetched);
        } else if (item.value === LAST_7_DAYS) {
            reportingPeriodStartDate = getPreviousDays(reportLastFetched, 7);
            reportingPeriodEndDate = getPreviousDays(reportLastFetched, 1);
        } else if (item.value === PREVIOUS_MONTH) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "month"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "month"
            );
        } else if (item.value === PREVIOUS_QUARTER) {
            reportingPeriodStartDate = getPreviousDaysStart(
                reportLastFetched,
                "quarter"
            );
            reportingPeriodEndDate = getPreviousDaysEnd(
                reportLastFetched,
                "quarter"
            );
        } else if (item.value === CUSTOM_DATE) {
            reportingPeriodStartDate =
                selectedViewData[0].EffectiveStartDate.substring(0, 10);
            reportingPeriodEndDate =
                selectedViewData[0].EffectiveEndDate.substring(0, 10);
            setCustomDateRange([
                {
                    startDate: selectedViewData[0].EffectiveStartDate.substring(
                        0,
                        10
                    ),
                    endDate: selectedViewData[0].EffectiveEndDate.substring(
                        0,
                        10
                    ),
                    key: "selection",
                    selectedFromDate:
                        selectedViewData[0].EffectiveStartDate.substring(0, 10),
                    selectedEndDate:
                        selectedViewData[0].EffectiveEndDate.substring(0, 10),
                },
            ]);
            setDisableConfirm(true);
        }
        let reportStatus = "";
        if (selectedViewData[0].Status === "All") {
            reportStatus = getMappedValues(status.map((value) => value.value));
        }

        if (coverageType && coverageType !== undefined) {
            if (reportViewType === paymentHistoryCard) {
                getClaimsPaymentSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        claimCoverageTypes: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        paymentStatus: reportStatus,
                        startIndex: 0,
                        startPage: 1,
                        count: 100,
                    },
                });
            } else if (reportViewType === disabilityCard) {
                getClaimsSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        claimCoverageTypes: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        claimStatus: reportStatus,
                        startIndex: 0,
                        startPage: 1,
                        count: 100,
                    },
                });
            } else if (reportViewType === leaveCard) {
                getLeavesSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        coverageType: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        startIndex: 0,
                        startPage: 1,
                        count: 100,
                        leaveScheduleType: periodScheduleTypeValue.map(
                            (item) => item.value
                        ),
                        historicalLeaveInd:
                            includeHistoricalLeaveValue.value === "No"
                                ? "N"
                                : "Y",
                        leavePeriodStatus: periodStatusValue.map(
                            (item) => item.value
                        ),
                        leaveStatus: reportingStatusValue.map(
                            (item) => item.value
                        ),
                        protectionStatus: protectionStatusValue.map(
                            (item) => item.value
                        ),
                    },
                });
            } else if (reportViewType === absenceCard) {
                getClaimsSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        claimCoverageTypes: coverageType,
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        claimStatus: reportStatus,
                        startIndex: 0,
                        startPage: 1,
                        count: 100,
                    },
                });
                getLeavesSearch({
                    filters: {
                        organizationId: selectedIsfOrganizationIds,
                        coverageType: coverageType[4],
                        effectiveStartDate: reportingPeriodStartDate,
                        effectiveEndDate: reportingPeriodEndDate,
                        startIndex: 0,
                        startPage: 1,
                        count: 100,
                    },
                });
            }
        }
        setSelectedFilters({
            ...selectedFilters,
            coverageType: "",
            policiesAndDivisions: [],
        });
    }, [organizationIDISF, coverageType]);

    const handleFiltersDataForSelectedView = (item) => {
        handleFiltersDateForSelectedView(item);
        setSelectedCoverageType(
            item.CoverageType.map((code) => ({
                value: code,
                label: code,
            }))
        );
        setSelectedPolicyDivision(initialPolicyDivision);

        let modifiedReportingStatus = null;
        if (item.Status === "All") {
            modifiedReportingStatus = status;
        } else {
            modifiedReportingStatus = item.Status.split(",").map((item) => ({
                value: item,
                label: item,
            }));
        }
        setReportingStatusValue(modifiedReportingStatus);
        if (
            item.EffectiveStartDate === STANDARD_REPORT_DATE_TIME &&
            item.EffectiveEndDate === STANDARD_REPORT_DATE_TIME
        ) {
            setPeriodScheduleTypeValue(PERIOD_SCHEDULE_TYPE);
            setIncludeHistoricalLeaveValue({ value: "No", label: "No" });
            setProtectionStatusValue(PROTECTION_STATUS);
            setPeriodStatusValue(PERIOD_STATUS);
        } else {
            setPeriodScheduleTypeValue(
                item?.PeriodScheduleTypes?.map((item) => ({
                    value: item,
                    label: item,
                }))
            );
            const historicalValueToSet = item.IncludeHistoricalLeave
                ? { value: "Yes", label: "Yes" }
                : { value: "No", label: "No" };
            setIncludeHistoricalLeaveValue(historicalValueToSet);
        }
    };

    const handleFiltersDateForSelectedView = (item) => {
        const startDate = moment(item.EffectiveStartDate);
        const endDate = moment(item.EffectiveEndDate);
        const differenceInDays = endDate.diff(startDate, "days");

        let reportindPeriodValue = null;
        if (differenceInDays === 1) {
            reportindPeriodValue = {
                value: PREVIOUS_DAY,
                label: PREVIOUS_DAY + previousDayDate,
            };
        } else if (differenceInDays === 6) {
            reportindPeriodValue = {
                value: LAST_7_DAYS,
                label: LAST_7_DAYS_LABEL + last7DayDate,
            };
        } else if (differenceInDays > 25 && differenceInDays < 35) {
            reportindPeriodValue = {
                value: PREVIOUS_MONTH,
                label: PREVIOUS_MONTH + previousMonthDate,
            };
        } else if (differenceInDays > 75 && differenceInDays < 95) {
            reportindPeriodValue = {
                value: PREVIOUS_QUARTER,
                label: PREVIOUS_QUARTER + previousQuaterDate,
            };
        } else if (
            item.EffectiveStartDate === STANDARD_REPORT_DATE_TIME &&
            item.EffectiveEndDate === STANDARD_REPORT_DATE_TIME
        ) {
            if (reportViewType === "PaymentHistory") {
                reportindPeriodValue = {
                    value: PREVIOUS_MONTH,
                    label: PREVIOUS_MONTH + previousMonthDate,
                };
            } else {
                reportindPeriodValue = {
                    value: LAST_7_DAYS,
                    label: LAST_7_DAYS_LABEL + last7DayDate,
                };
            }
        } else {
            reportindPeriodValue = {
                value: CUSTOM_DATE,
                label:
                    CUSTOM_DATE +
                    moment(startDate).format(mmDateFormat) +
                    " - " +
                    moment(endDate).format(mmDateFormat),
            };
        }
        setReportingPeriodValue({
            value: reportindPeriodValue.value,
            label: reportindPeriodValue.label,
        });

        let modifiedReportingStatus = null;
        if (item.Status === "All") {
            modifiedReportingStatus = status;
        } else {
            modifiedReportingStatus = item.Status.split(",").map((item) => ({
                value: item,
                label: item,
            }));
        }
        setReportingStatusValue(modifiedReportingStatus);
        if (
            item.EffectiveStartDate === STANDARD_REPORT_DATE_TIME &&
            item.EffectiveEndDate === STANDARD_REPORT_DATE_TIME
        ) {
            setPeriodScheduleTypeValue(PERIOD_SCHEDULE_TYPE);
            setIncludeHistoricalLeaveValue({ value: "No", label: "No" });
            setProtectionStatusValue(PROTECTION_STATUS);
            setPeriodStatusValue(PERIOD_STATUS);
        } else {
            setPeriodScheduleTypeValue(
                item?.PeriodScheduleTypes?.map((item) => ({
                    value: item,
                    label: item,
                }))
            );
            setPeriodStatusValue(
                item?.PeriodStatuses?.map((item) => ({
                    value: item,
                    label: item,
                }))
            );
            setProtectionStatusValue(
                item?.ProtectionStatuses?.map((item) => ({
                    value: item,
                    label: item,
                }))
            );
            const historicalValueToSet = item.IncludeHistoricalLeave
                ? { value: "Yes", label: "Yes" }
                : { value: "No", label: "No" };
            setIncludeHistoricalLeaveValue(historicalValueToSet);
        }
    };

    const handleDateRangeChange = (item) => {
        if (item.reset) {
            setCustomDateRange([
                {
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                    label: CUSTOM_DATE,
                    selectedFromDate: null,
                    selectedEndDate: null,
                },
            ]);
        } else if (item.confirm) {
            setReportingPeriodValue({
                value: CUSTOM_DATE,
                label:
                    CUSTOM_DATE +
                    moment(customDateRange[0].startDate).format(mmDateFormat) +
                    " - " +
                    moment(customDateRange[0].endDate).format(mmDateFormat),
            });
            setEnableReset(true);
            setEnableApply(true);
        } else {
            if (selectionMode === "start") {
                // Odd clicks (1st, 3rd, 5th, etc.) set startDate
                setCustomDateRange([
                    {
                        ...customDateRange[0],
                        startDate: item.selection.startDate,
                        selectedFromDate: item.selection.startDate,
                        endDate: item.selection.startDate,
                        key: "selection",
                        label: CUSTOM_DATE,
                        selectedEndDate: null,
                    },
                ]);
                setSelectionMode("end");
            } else if (selectionMode === "end") {
                setCustomDateRange([
                    {
                        ...customDateRange[0],
                        startDate: item.selection.startDate,
                        selectedFromDate: item.selection.startDate,
                        endDate: item.selection.endDate,
                        selectedEndDate: item.selection.endDate,
                        key: "selection",
                        label: CUSTOM_DATE,
                    },
                ]);
                setSelectionMode("start");
                setDisableConfirm(false);
            }
        }
    };

    useEffect(() => {
        if (
            reportTableData &&
            Array.isArray(reportTableData.claimsSearch) &&
            reportLeaveTableData &&
            Array.isArray(reportLeaveTableData.LeaveSearch)
        ) {
            const formattedAbsenceViewData = AbsenceSearch.format(
                reportLeaveTableData,
                reportTableData
            );
            setAbsenceViewData(formattedAbsenceViewData);
        }
    }, [reportTableData, reportLeaveTableData]);

    const handleCoverage = (coverage) => {
        setSelectedCoverageType(coverage);
        setEnableReset(true);
        setEnableApply(true);
    };
    const handleStatus = (status) => {
        setReportingStatusValue(status);
        setEnableReset(true);
        setEnableApply(true);
    };
    const handlePeriodScheduleTypeValueChange = (item) => {
        setPeriodScheduleTypeValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleIncludeHistoricalValueChange = (item) => {
        setIncludeHistoricalLeaveValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handlePeriodStatusValueChange = (item) => {
        setPeriodStatusValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleProtectionStatusValueChange = (item) => {
        setProtectionStatusValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleReportingPeriodChange = (item) => {
        setReportingPeriodValue(item);
        setEnableReset(true);
        setEnableApply(true);
    };

    const handleResetFilter = () => {
        handleFiltersDataForSelectedView(selectedViewData[0]);
        handleDateRangeChange({
            reset: true,
        });
        setEnableReset(false);
        setEnableApply(true);
    };

    const onHandleOutsideClick = () => {
        if (reportingPeriodValue.label === CUSTOM_DATE) {
            handleFiltersDateForSelectedView(selectedViewData[0]);
            handleDateRangeChange({
                reset: true,
            });
        } else {
            if (reportingPeriodValue.value !== CUSTOM_DATE) {
                handleDateRangeChange({
                    reset: true,
                });
            }
            setReportingPeriodValue(reportingPeriodValue);
        }
        setEnableReset(true);
        setEnableApply(true);
    };

    let isFetchingValue;

    if (reportViewType === disabilityCard) {
        isFetchingValue = isFetching;
    } else if (reportViewType === paymentHistoryCard) {
        isFetchingValue = isFetchingPayment;
    } else if (reportViewType === leaveCard) {
        isFetchingValue = isFetchingLeave;
    } else if (reportViewType === absenceCard) {
        isFetchingValue = isFetching && isFetchingLeave;
    } else {
        isFetchingValue = false;
    }

    let reportTableDataValue;

    if (reportViewType === disabilityCard) {
        reportTableDataValue = reportTableData.claimsSearch;
    } else if (reportViewType === paymentHistoryCard) {
        reportTableDataValue = reportPaymentTableData.claimsPaymentSearch;
    } else if (reportViewType === leaveCard) {
        reportTableDataValue = reportLeaveTableData.LeaveSearch;
    } else if (reportViewType === absenceCard) {
        reportTableDataValue = absenceViewData;
    } else {
        reportTableDataValue = "";
    }

    let initializedValue;

    if (reportViewType === disabilityCard) {
        initializedValue = isUninitialized;
    } else if (reportViewType === paymentHistoryCard) {
        initializedValue = isUninitializedPayment;
    } else if (reportViewType === leaveCard) {
        initializedValue = isUninitializedLeave;
    } else if (reportViewType === absenceCard) {
        initializedValue = isUninitialized && isUninitializedLeave;
    } else {
        initializedValue = false;
    }

    let isErrorValue;

    if (reportViewType === disabilityCard) {
        isErrorValue = isError;
    } else if (reportViewType === paymentHistoryCard) {
        isErrorValue = isErrorPayment;
    } else if (reportViewType === leaveCard) {
        isErrorValue = isErrorLeave;
    } else if (reportViewType === absenceCard) {
        isErrorValue = isError && isErrorLeave;
    } else {
        isErrorValue = false;
    }

    return (
        <>
            <ReportViewFilter
                organizationName={organizationName}
                userSelectedOrganizationReports={
                    userSelectedOrganizationReports
                }
                reportId={reportId}
                relatedOrganizationNames={relatedOrganizationNames}
                title={
                    selectedReport?.DataViewType
                        ? selectedReport?.DataViewType
                        : title
                }
                coverageType={coverageType}
                isFetching={isFetchingValue}
                allowedPoliciesAndDivisions={allowedPoliciesAndDivisions}
                //filters
                coverageTypeOptions={coverageTypeOptions}
                reportingPeriod={reporting_Period}
                status={status}
                reportingGroup={reportingGroup}
                handleApplyFilter={handleApplyFilter}
                selectedFilters={selectedFilters}
                reportRanDate={reportLastFetched}
                reportViewType={reportViewType}
                defaultReportingPeriod={reportingPeriodValue}
                defaultReportingStatus={reportingStatusValue}
                customDateRange={customDateRange}
                handleDateRangeChange={handleDateRangeChange}
                selectedCoverageType={selectedCoverageType}
                handleCoverage={handleCoverage}
                selectedStatus={reportingStatusValue}
                handleStatus={handleStatus}
                selectedPolicyDivision={selectedPolicyDivision}
                handlePolicyDivision={handlePolicyDivision}
                initialPolicyDivision={initialPolicyDivision}
                includeHistoricalLeave={INCLUDE_HISTORICAL_LEAVE}
                periodScheduleType={PERIOD_SCHEDULE_TYPE}
                periodStatus={PERIOD_STATUS}
                protectionStatus={PROTECTION_STATUS}
                includeHistoricalLeaveValue={includeHistoricalLeaveValue}
                periodScheduleTypeValue={periodScheduleTypeValue}
                periodStatusValue={periodStatusValue}
                protectionStatusValue={protectionStatusValue}
                onIncludeHistoricalValueChange={
                    handleIncludeHistoricalValueChange
                }
                onPeriodScheduleTypeValueChange={
                    handlePeriodScheduleTypeValueChange
                }
                onPeriodStatusValueChange={handlePeriodStatusValueChange}
                onProtectionStatusValueChange={
                    handleProtectionStatusValueChange
                }
                onReportingPeriodChange={handleReportingPeriodChange}
                onHandleReset={handleResetFilter}
                enableReset={enableReset}
                enableApply={enableApply}
                onHandleOutsideClick={onHandleOutsideClick}
                disableConfirm={disableConfirm}
            />
            <ReportViewData
                organizationIDISF={organizationIDISF}
                selectedReport={selectedReport}
                reportData={reportData}
                dataViewType={reportViewType}
                reportViewInformation={selectedViewData}
                onViewOptionChange={handleViewOptionChange}
                onViewReportSave={handleViewReportSave}
                allViewsData={allViewsData}
                loaderStatus={loaderStatus}
                onViewReportUpdate={handleViewReportUpdate}
                reportTableData={reportTableDataValue}
                isFetching={isFetchingValue}
                isUninitialized={initializedValue}
                isError={isErrorValue}
                handleCoverageType={handleCoverageType}
                notificationStatus={notificationMessageUpdate}
                handleViewID={handleViewID}
                reportLastFetched={reportLastFetched}
                userEmail={user.userEmail}
                filterValues={selectedFilters}
                initialFiltersValue={initialFiltersValue}
                portalUserApiError={portalUserApiError}
                organizationsAccess={organizationsAccess[reportId]}
                emitFiltersDataForSelectedView={
                    handleFiltersDataForSelectedView
                }
                selectedFilterStatus={selectedFilterStatus}
                reportingPeriodValue={reportingPeriodValue}
                reportingStatusValue={reportingStatusValue}
                selectedCoverageType={selectedCoverageType}
                selectedPolicyDivision={selectedPolicyDivision}
            />
            {isFetching || isFetchingPayment ? (
                <FooterNote date="..." />
            ) : (
                <FooterNote date={reportLastFetched} />
            )}
            {showReportSaveNotification && (
                <ReportSaveNotification
                    onCloseNotification={handleReportSavedNotificationClose}
                    msgIndicator={notificationMessageIndicator}
                ></ReportSaveNotification>
            )}
            {showReportSaveErrorNotification && (
                <ReportSaveErrorNotification
                    onCloseNotification={handleReportErrorNotificationClose}
                ></ReportSaveErrorNotification>
            )}
        </>
    );
}

export default ReportViewPage;
