const host = document.location.host;

let config = {};

if (host.includes("localhost")) {
    config = {
        REACT_APP_ENV: "staging",
        REACT_APP_ENV_NAME: "/dev1",
        REACT_GTM_ID: "GTM-N4TX5WS",
        REACT_GTM_AUTH: "8lYl4QiVeRxWBJUem2HaFA",
        REACT_GTM_PREVIEW: "env-39",
        REACT_CLIENT_SIDE_ID: "64c2616bf867cb13ce62bdcd", //LaunchDarkly ClientSideID
        REACT_APP_BASE_URL: "https://unuminsights.acpt.unum.com",
        REACT_APP_ISERVICES_URL:
            "http://uaservices.unum.com/ClaimLeaveReporting/Extensions/ClaimHistory/",
        REACT_APP_OUTSTANDING_INFO_ISERVICES_URL:
            "https://uaservices.unum.com/ClaimLeaveReporting/Extensions/OutstandingInformation/",
        REACT_APP_SSO_COOKIE_NAME: "unumEnterpriseSSO_ACPT",
        REACT_APP_CLI: "https://cognosgw-group1-acpt.unum.com/ibmcognos",
        REACT_APP_CLI_INTERNAL:
            "https://cognosgw-group2-acpt.unum.com/ibmcognos",
        REACT_APP_ISERVICES: "https://uaservices.unum.com",
        REACT_APP_MY_UNUM:
            "https://sso.acpt.unum.com/auth/idpssoinit?metaAlias=/external/idp2&spEntityID=https://myunumPortalSalesforceSP",
        REACT_APP_API_URL: "https://acpt-connect.unum.com/insights",
        REACT_APP_ENTERPRISE_INFO_API_URL:
            "https://api.acpt.unum.com/enterprise-information/",
        REACT_APP_TABLEAU_URL: "https://employer-reporting-ua.unum.com/",
        REACT_APP_EXTERNAL_PREFERENCE_API_URL:
            "https://api.acpt.unum.com/insights",

        REACT_APP_TABLEAU_API_SITEID: "f53babff-ab5e-471d-aefe-6545776a490e",
        REACT_APP_INT_ACCESS_URL:
            "https://intsso.acpt.unum.com/auth/idpssoinit?metaAlias=/internal/tableau_cli_idp&spEntityID=https://employer-reporting-ua.unum.com/",
        REACT_APP_COSTCO_URL :
            "https://cognosgw-costco-prod.unum.com/ibmcognos",
    };
}

if (host === "insights.dev.unum.com") {
    config = {
        REACT_APP_ENV: "production",
        REACT_APP_ENV_NAME: "/dev1",
        REACT_APP_BASE_URL: "http://insights.dev.unum.com",
        REACT_APP_ISERVICES_URL:
            "http://dev1-services.unum.com/ClaimLeaveReporting/Extensions/ClaimHistory/",
        REACT_CLIENT_SIDE_ID: "64c26176812d0213823bbc5a",
        REACT_APP_OUTSTANDING_INFO_ISERVICES_URL:
            "https://dev1-services.unum.com/ClaimLeaveReporting/Extensions/OutstandingInformation/",
        REACT_APP_SSO_COOKIE_NAME: "unumEnterpriseSSO_DEV",
        REACT_APP_CLI: "https://cognosgw-group1-dev1.unum.com/ibmcognos",
        REACT_APP_CLI_INTERNAL:
            "https://cognosgw-group2-dev1.unum.com/ibmcognos",
        REACT_APP_ISERVICES: "https://dev1-services.unum.com",
        REACT_APP_API_URL: "https://acpt-connect.unum.com/insights",
        REACT_APP_MY_UNUM:
            "https://sso.dev.unum.com/auth/idpssoinit?metaAlias=/external/idp2&spEntityID=https://myunumPortalSalesforceSP",
        REACT_APP_TABLEAU_URL: "https://employer-reporting-dev.unum.com/",
        REACT_APP_EXTERNAL_PREFERENCE_API_URL:
            "https://api.acpt.unum.com/insights",
        REACT_APP_SECURITY_API_URL:
            "https://acpt-connect.unum.com/security/access/v4/auth/json/sessions",
        REACT_APP_TABLEAU_API_SITEID: "ee2958fd-06c6-494b-b769-58fe5b25ac3b",
        REACT_APP_INT_ACCESS_URL:
            "https://intsso.dev1.unum.com/auth/idpssoinit?metaAlias=/internal/tableau_cli_idp&spEntityID=https://employer-reporting-dev.unum.com/",
        REACT_APP_ENTERPRISE_INFO_API_URL:
            "https://acpt-connect.unum.com/enterprise-information/",
        REACT_APP_COSTCO_URL :
            "https://cognosgw-costco-prod.unum.com/ibmcognos",
    };
}

if (host === "unuminsights.dev.unum.com") {
    config = {
        REACT_APP_ENV: "production",
        REACT_APP_ENV_NAME: "/dev1",
        REACT_APP_BASE_URL: "http://unuminsights.dev.unum.com",
        REACT_APP_ISERVICES_URL:
            "http://dev1-services.unum.com/ClaimLeaveReporting/Extensions/ClaimHistory/",
        REACT_CLIENT_SIDE_ID: "64c26176812d0213823bbc5a",
        REACT_APP_OUTSTANDING_INFO_ISERVICES_URL:
            "https://dev1-services.unum.com/ClaimLeaveReporting/Extensions/OutstandingInformation/",
        REACT_APP_SSO_COOKIE_NAME: "unumEnterpriseSSO_DEV",
        REACT_APP_CLI: "https://cognosgw-group1-dev1.unum.com/ibmcognos",
        REACT_APP_CLI_INTERNAL:
            "https://cognosgw-group2-dev1.unum.com/ibmcognos",
        REACT_APP_ISERVICES: "https://dev1-services.unum.com",
        REACT_APP_API_URL: "https://acpt-connect.unum.com/insights",
        REACT_APP_MY_UNUM:
            "https://sso.dev.unum.com/auth/idpssoinit?metaAlias=/external/idp2&spEntityID=https://myunumPortalSalesforceSP",
        REACT_APP_TABLEAU_URL: "https://employer-reporting-dev.unum.com/",
        REACT_APP_EXTERNAL_PREFERENCE_API_URL:
            "https://api.acpt.unum.com/insights",
        REACT_APP_SECURITY_API_URL:
            "https://acpt-connect.unum.com/security/access/v4/auth/json/sessions",
        REACT_APP_TABLEAU_API_SITEID: "ee2958fd-06c6-494b-b769-58fe5b25ac3b",
        REACT_APP_INT_ACCESS_URL:
            "https://intsso.dev1.unum.com/auth/idpssoinit?metaAlias=/internal/tableau_cli_idp&spEntityID=https://employer-reporting-dev.unum.com/",
        REACT_APP_ENTERPRISE_INFO_API_URL:
            "https://acpt-connect.unum.com/enterprise-information/",
        REACT_APP_COSTCO_URL :
            "https://cognosgw-costco-prod.unum.com/ibmcognos",
    };
}

if (host === "unuminsights.itest.unum.com") {
    config = {
        REACT_APP_ENV: "itest",
        REACT_APP_ENV_NAME: "/itest1",
        REACT_GTM_ID: "GTM-N4TX5WS",
        REACT_GTM_AUTH: "N24MA2Y2ysgsgXPTiUpHCQ",
        REACT_GTM_PREVIEW: "env-40",
        REACT_CLIENT_SIDE_ID: "64b57965a8e6531428126a8a", //LaunchDarkly ClientSideID
        REACT_APP_BASE_URL: "https://unuminsights.itest.unum.com",
        REACT_APP_ISERVICES_SSO_URL:
            "https://sso.itest1.unum.com/auth/idpssoinit?metaAlias=/external/idp_iservices&spEntityID=iServices&RelayState=",
        REACT_APP_ISERVICES_URL:
            "https://itest1-services.unum.com/ClaimLeaveReporting/Extensions/ClaimHistory/",
        REACT_APP_OUTSTANDING_INFO_ISERVICES_URL:
            "https://itest1-services.unum.com/ClaimLeaveReporting/Extensions/OutstandingInformation/",
        REACT_APP_SSO_COOKIE_NAME: "unumEnterpriseSSO_ITEST",
        REACT_APP_CLI: "https://cognosgw-group1-itest1.unum.com/ibmcognos",
        REACT_APP_CLI_INTERNAL:
            "https://cognosgw-group2-itest1.unum.com/ibmcognos",
        REACT_APP_ISERVICES: "https://itest1-services.unum.com",
        REACT_APP_MY_UNUM:
            "https://sso.itest1.unum.com/auth/idpssoinit?metaAlias=/external/idp1&spEntityID=https://myunumPortalSalesforceSP",
        REACT_APP_API_URL: "https://acpt-connect.unum.com/insights",
        REACT_APP_TABLEAU_URL: "https://employer-reporting-ua.unum.com/",
        REACT_APP_EXTERNAL_PREFERENCE_API_URL:
            "https://api.acpt.unum.com/insights",
        REACT_APP_TABLEAU_API_SITEID: "f53babff-ab5e-471d-aefe-6545776a490e",
        REACT_APP_INT_ACCESS_URL:
            "https://intsso.acpt.unum.com/auth/idpssoinit?metaAlias=/internal/tableau_cli_idp&spEntityID=https://employer-reporting-ua.unum.com/",
        REACT_APP_ENTERPRISE_INFO_API_URL:
            "https://acpt-connect.unum.com/enterprise-information/",
        REACT_APP_SECURITY_API_URL:
            "https://acpt-connect.unum.com/security/access/v4/auth/json/sessions",
        REACT_APP_COSTCO_URL :
            "https://cognosgw-costco-prod.unum.com/ibmcognos",
    };
}

if (host === "unuminsights.acpt.unum.com") {
    config = {
        REACT_APP_ENV: "staging",
        REACT_APP_ENV_NAME: "",
        REACT_GTM_ID: "GTM-N4TX5WS",
        REACT_GTM_AUTH: "8lYl4QiVeRxWBJUem2HaFA",
        REACT_GTM_PREVIEW: "env-39",
        REACT_CLIENT_SIDE_ID: "64c2616bf867cb13ce62bdcd", //LaunchDarkly ClientSideID
        REACT_APP_BASE_URL: "https://unuminsights.acpt.unum.com",
        REACT_APP_ISERVICES_SSO_URL:
            "https://sso.acpt.unum.com/auth/idpssoinit?metaAlias=/external/idp_iservices&spEntityID=iServices&RelayState=",
        REACT_APP_ISERVICES_URL:
            "https://uaservices.unum.com/ClaimLeaveReporting/Extensions/ClaimHistory/",
        REACT_APP_OUTSTANDING_INFO_ISERVICES_URL:
            "https://uaservices.unum.com/ClaimLeaveReporting/Extensions/OutstandingInformation/",
        REACT_APP_SSO_COOKIE_NAME: "unumEnterpriseSSO_ACPT",
        REACT_APP_CLI: "https://cognosgw-group1-acpt.unum.com/ibmcognos",
        REACT_APP_CLI_INTERNAL:
            "https://cognosgw-group2-acpt.unum.com/ibmcognos",
        REACT_APP_ISERVICES: "https://uaservices.unum.com",
        REACT_APP_MY_UNUM:
            "https://sso.acpt.unum.com/auth/idpssoinit?metaAlias=/external/idp2&spEntityID=https://myunumPortalSalesforceSP",
        REACT_APP_API_URL: "https://acpt-connect.unum.com/insights",
        REACT_APP_TABLEAU_URL: "https://employer-reporting-ua.unum.com/",
        REACT_APP_EXTERNAL_PREFERENCE_API_URL:
            "https://api.acpt.unum.com/insights",

        REACT_APP_TABLEAU_API_SITEID: "f53babff-ab5e-471d-aefe-6545776a490e",
        REACT_APP_INT_ACCESS_URL:
            "https://intsso.acpt.unum.com/auth/idpssoinit?metaAlias=/internal/tableau_cli_idp&spEntityID=https://employer-reporting-ua.unum.com/",
        REACT_APP_ENTERPRISE_INFO_API_URL:
            "https://acpt-connect.unum.com/enterprise-information/",
        REACT_APP_SECURITY_API_URL:
            "https://acpt-connect.unum.com/security/access/v4/auth/json/sessions",
        REACT_APP_COSTCO_URL :
            "https://cognosgw-costco-prod.unum.com/ibmcognos",
    };
}

if (host === "unuminsights.unum.com") {
    config = {
        REACT_APP_ENV: "prod",
        REACT_APP_ENV_NAME: "",
        REACT_GTM_ID: "GTM-N4TX5WS",
        REACT_GTM_AUTH: "LHGlSlB3-jwdVsmOv3svYw",
        REACT_GTM_PREVIEW: "env-38",
        REACT_APP_BASE_URL: "https://unuminsights.unum.com",
        REACT_CLIENT_SIDE_ID: "64b57965a8e6531428126a8b",
        REACT_APP_ISERVICES_SSO_URL:
            "https://sso.unum.com/auth/idpssoinit?metaAlias=/external/idp_iservices&spEntityID=iServices&RelayState=",
        REACT_APP_ISERVICES_URL:
            "https://services.unum.com/ClaimLeaveReporting/Extensions/ClaimHistory/",
        REACT_APP_OUTSTANDING_INFO_ISERVICES_URL:
            "https://services.unum.com/ClaimLeaveReporting/Extensions/OutstandingInformation/",
        REACT_APP_SSO_COOKIE_NAME: "unumEnterpriseSSO",
        REACT_APP_CLI: "https://cognosgw-group1-prod.unum.com/ibmcognos",
        REACT_APP_CLI_INTERNAL:
            "https://cognosgw-group2-prod.unum.com/ibmcognos",
        REACT_APP_ISERVICES: "https://services.unum.com",
        REACT_APP_MY_UNUM:
            "https://sso.unum.com/auth/idpssoinit?metaAlias=/external/myunumportalIDP&spEntityID=https://myunumPortalSalesforceSP",
        REACT_APP_API_URL: "https://connect.unum.com/insights",
        REACT_APP_TABLEAU_URL: "https://employer-reporting.unum.com/",
        REACT_APP_EXTERNAL_PREFERENCE_API_URL: "https://api.unum.com/insights",
        REACT_APP_INT_ACCESS_URL:
            "https://intsso.unum.com/auth/idpssoinit?metaAlias=/internal/tableau_cli_idp&spEntityID=https://employer-reporting.unum.com/",
        REACT_APP_ENTERPRISE_INFO_API_URL:
            "https://connect.unum.com/enterprise-information/",
        REACT_APP_SECURITY_API_URL:
            "https://connect.unum.com/security/access/v4/auth/json/sessions",
        REACT_APP_COSTCO_URL :
            "https://cognosgw-costco-prod.unum.com/ibmcognos",
    };
}

export default config;
