import React from "react";
import Modal from "react-bootstrap/Modal";
import SectionHeading from "../../components/SectionHeading";
import SectionSubheading from "../../components/SectionSubheading";
import Button from "../../components/ui/Button";
import ManageWeekly from "./ManageWeekly";
import ManageMonthly from "./ManageMonthly";

const SELECTED_PATTERN_CLASS = "selected-pattern";

export default function ManageReminderDialogBox({
    onShow,
    onSave,
    onDontSave,
    reportName,
    onReminderCheck,
    showFrequency,
    onFrequencyPatternClick,
    showFrequencyPattern,
    onRecurrencePatternClick,
    showRecurrencePattern,
    handleDaysOfTheWeek,
    handleMonthly,
    disableSaveButton,
    subscriptionInfo,
    reminderCheck,
    daysOfMonth
}) {
    const sendSaveEvent = () => onSave();
    const sendDontSaveEvent = () => onDontSave();
    const handleReminderCheck = (event) => {
        onReminderCheck(event.target.checked);
    };

    const handleFrequencyPattern = (item) => onFrequencyPatternClick(item);
    const handleRecurrencePattern = (item) => onRecurrencePatternClick(item);

    const handleWeeklyClick = () => {
        handleFrequencyPattern("Weekly");
    };

    const handleMonthlyClick = () => {
        handleFrequencyPattern("Monthly");
    };
    
    return (
        <Modal
            size="sm"
            show={onShow}
            backdrop="static"
            keyboard={false}
            centered
            className="preference"
            dialogClassName="custom-modal-size"
        >
            <Modal.Header style={{ borderBottom: "1px solid  #AAAAAA" }}>
                <SectionHeading heading={reportName} />
                <i
                    className="fa-solid fa-circle-xmark cursor-pointer"
                    onClick={sendDontSaveEvent}
                ></i>
            </Modal.Header>
            <Modal.Body>
                <div style={{ width: 384 }}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <label style={{ paddingTop: 12 }}>
                            Enable reminder to receive updates
                        </label>
                        <div
                            className="form-check form-switch"
                            style={{ marginTop: 7 }}
                        >
                            <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="flexSwitchCheckDefault"
                                checked={reminderCheck}
                                onChange={handleReminderCheck}
                            />
                        </div>
                    </div>

                    {showFrequency && (
                        <>
                            <SectionSubheading
                                subheading="Tailor your Reminder preference to receive a notification for this view."
                                className="sub-heading-disabled pt-16p"
                            />
                            <label style={{ paddingTop: 12 }}>Frequency</label>
                            <div
                                className="frequently-used-views"
                                style={{ marginBottom: 0 }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div className="button-group frequency-select">
                                        <div
                                            className={`frequency-reminder ${
                                                showFrequencyPattern === "Daily"
                                                    ? SELECTED_PATTERN_CLASS
                                                    : ""
                                            }`}
                                            onClick={() =>
                                                handleFrequencyPattern("Daily")
                                            }
                                            style={{
                                                borderTopLeftRadius: 8,
                                                borderBottomLeftRadius: 8,
                                            }}
                                        >
                                            Daily
                                        </div>
                                        <div
                                            className={`frequency-reminder ${
                                                showFrequencyPattern ===
                                                "Weekly"
                                                    ? SELECTED_PATTERN_CLASS
                                                    : ""
                                            }`}
                                            onClick={handleWeeklyClick}
                                        >
                                            Weekly
                                        </div>
                                        <div
                                            className={`frequency-reminder ${
                                                showFrequencyPattern ===
                                                "Monthly"
                                                    ? SELECTED_PATTERN_CLASS
                                                    : ""
                                            }`}
                                            style={{
                                                borderRight: "none",
                                                borderTopRightRadius: 8,
                                                borderBottomRightRadius: 8,
                                            }}
                                            onClick={handleMonthlyClick}
                                        >
                                            Monthly
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {showFrequencyPattern === "Daily" && showFrequency && (
                        <div>
                            <label style={{ paddingTop: 12 }}>
                                Recurrence pattern
                            </label>
                            <div
                                className="frequently-used-views"
                                style={{ marginBottom: 0 }}
                            >
                                <div className="d-flex justify-content-between">
                                    <div className="button-group frequency-select">
                                        <div
                                            className={`frequency-reminder ${
                                                showRecurrencePattern ===
                                                "Everyday"
                                                    ? SELECTED_PATTERN_CLASS
                                                    : ""
                                            }`}
                                            style={{ width: 192 }}
                                            onClick={() =>
                                                handleRecurrencePattern(
                                                    "Everyday"
                                                )
                                            }
                                        >
                                            Everyday
                                        </div>
                                        <div
                                            className={`frequency-reminder ${
                                                showRecurrencePattern ===
                                                "Every weekday"
                                                    ? SELECTED_PATTERN_CLASS
                                                    : ""
                                            }`}
                                            style={{
                                                borderRight: "none",
                                                whiteSpace: "nowrap",
                                                paddingLeft: 35,
                                                width: 192,
                                            }}
                                            onClick={() =>
                                                handleRecurrencePattern(
                                                    "Every weekday"
                                                )
                                            }
                                        >
                                            Every weekday
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {showFrequencyPattern === "Weekly" && showFrequency && (
                        <ManageWeekly
                            handleDaysOfTheWeek={handleDaysOfTheWeek}
                            subscriptionInfo={subscriptionInfo}
                        />
                    )}
                    {showFrequencyPattern === "Monthly" && showFrequency && (
                        <ManageMonthly
                            handleMonthly={handleMonthly}
                            subscriptionInfo={subscriptionInfo}
                        />
                    )}
                </div>

                <div className="preference-button pt-16p">
                    <div className="d-contents">
                        <Button
                            data-testid="Cancel"
                            text="Cancel"
                            outline={true}
                            onClick={sendDontSaveEvent}
                        />
                        <Button
                            text="Save"
                            onClick={sendSaveEvent}
                            disabled={
                                !showFrequencyPattern || disableSaveButton
                            }
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
