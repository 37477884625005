import React, { useCallback, useState } from "react";
import mainLogo from "../assets/images/unum-Insights-logo.svg";
import { useSelector, useDispatch } from "react-redux";
import SubHeader from "./SubHeader";
import Sidebar from "./sidebar/Sidebar";
import { logout } from "./../slices/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import OrganizationLogo from "./OrganizationLogo";
import { displayLogo } from "../constants/index";
import exportIcon from "../assets/icons/export-icon.svg";
import GTM from "../GTM";
import { useFlags } from "launchdarkly-react-client-sdk";
import InfomationBanner from "./InfomationBanner";
import warning from "../assets/icons/warning-icon.svg";
import InformationBanner from "../assets/icons/displayInformationBanner-icon.svg";
import errorBanner from "../assets/icons/error-banner-icon.svg";
import WarningPage from "../views/preferences/WarningPage";
import { useEventContext } from "../EventContext";
import { setPreferenceChanged } from "../slices/preferenceSlice";
import PreferenceSaveNotification from "../views/preferences/PreferenceSaveNotification";

function Header({
    showInformationBanner,
    hideInformationBanner,
    isPreferenceSaved,
    handleSavedPreference,
}) {
    const { user, returnUrl } = useSelector((state) => state.auth);
    const [show, setShow] = useState(false);
    const [pageSelected, setPageSelected] = useState();
    const [pageGtmTags, setPageGtmTags] = useState();
    const { isPreferenceChanged } = useSelector(
        (state) => state.preferenceSlice
    );
    const { handleEvent } = useEventContext();
    const location = useLocation();
    const navigate = useNavigate();
    const {
        lastSelectedOrganization: organization,
        currentSelectedOrganization,
    } = useSelector((state) => state.organizations);
    const { config } = useSelector((state) => state.app);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const toggleRef = React.useRef(null);
    const { displayCostcoUrl } = useFlags();
    const dispatch = useDispatch();
    const gtm = new GTM(null);

    const closeSidebar = useCallback(() => {
        setSidebarOpen(false);
    }, []);

    const handleGtmTags = (label) => {
        gtm.Event(
            "top nav",
            "link",
            "click",
            label,
            "top nav",
            "",
            "navigation_click",
            ""
        );
    };

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    let url = location.pathname;

    let urlDisplay = url.match(displayLogo);

    const {
        displayWarningBanner,
        displayInformationBanner,
        displayErrorbanner,
    } = useFlags();

    const handleInformationBannerClose = () => {
        hideInformationBanner();
    };

    const emitEvent = async () => {
        await handleEvent({ eventType: "ONSAVE_EVENT" });
        dontSave();
    };

    const dontSave = () => {
        setShow(false);
        dispatch(setPreferenceChanged(false));
        navigate(pageSelected);
        handleGtmTags(pageGtmTags);
    };

    const handleKeyDown = (e, onClick) => {
        if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            onClick();
        }
    };

    const renderInformationBanner = (
        showInformationBanner,
        displayWarningBanner,
        displayInformationBanner,
        displayErrorbanner,
        handleInformationBannerClose
    ) => {
        return (
            <>
                {showInformationBanner && displayWarningBanner && (
                    <InfomationBanner
                        bannerType="warning"
                        bannerImage={warning}
                        bannerText={displayWarningBanner}
                        onClose={handleInformationBannerClose}
                    />
                )}
                {showInformationBanner && displayInformationBanner && (
                    <InfomationBanner
                        bannerType="information"
                        bannerImage={InformationBanner}
                        bannerText={displayInformationBanner}
                        onClose={handleInformationBannerClose}
                    />
                )}
                {showInformationBanner && displayErrorbanner && (
                    <InfomationBanner
                        bannerType="error"
                        bannerImage={errorBanner}
                        bannerText={displayErrorbanner}
                        onClose={handleInformationBannerClose}
                    />
                )}
            </>
        );
    };

    const {
        selectedOrganizations: userSelectedOrganizations,
        selectedOrganizationsId: id,
    } = useSelector((state) => state.organizations);

    // Check if any of the selected organizations match "COSTCO WHOLESALE CORPORATION" or "Costco Companies"
    const isCostcoUser = (userSelectedOrganizations) => {
        const organizationName =
            userSelectedOrganizations[
                id
            ]?.related[0]?.ORGANIZATIONGROUPNAME?.toUpperCase();
        return organizationName?.includes("COSTCO");
    };

    const renderNavigationLinks = (
        url,
        isPreferenceChanged,
        navigate,
        handleGtmTags,
        setPageSelected,
        setPageGtmTags,
        setShow
    ) => {
        const handleBackButtonClick = (item) => {
            setPageSelected(item);
            setPageGtmTags(item.slice(1));
            if (isPreferenceChanged === true) {
                setShow(true);
            } else {
                setShow(false);
            }
        };

        return (
            <>
                <div
                    className={
                        (url.includes("/search") ? "text-bold " : "") + "mr-16p"
                    }
                    data-testid="Search"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (!isPreferenceChanged) {
                            navigate("/search");
                            handleGtmTags("search");
                        }
                        handleBackButtonClick("/search");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            if (!isPreferenceChanged) {
                                navigate("/search");
                                handleGtmTags("search");
                            }
                            handleBackButtonClick("/search");
                        })
                    }
                >
                    Search
                </div>
                <div
                    className={
                        (url.includes("/reportingcapabilities")
                            ? "text-bold "
                            : "") + "mr-16p"
                    }
                    data-testid="reports"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (!isPreferenceChanged) {
                            navigate("/reportingcapabilities");
                            handleGtmTags("reports");
                        }
                        handleBackButtonClick("/reportingcapabilities");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            if (!isPreferenceChanged) {
                                navigate("/reportingcapabilities");
                                handleGtmTags("reports");
                            }
                            handleBackButtonClick("/reportingcapabilities");
                        })
                    }
                >
                    Reports
                </div>
                <div
                    className="d-none mr-16p"
                    data-testid="dashboard"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (!isPreferenceChanged) {
                            navigate("/dashboard");
                            handleGtmTags("dashboard");
                        }
                        handleBackButtonClick("/dashboard");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            if (!isPreferenceChanged) {
                                navigate("/dashboard");
                                handleGtmTags("dashboard");
                            }
                            handleBackButtonClick("/dashboard");
                        })
                    }
                >
                    Dashboard
                </div>
                <div
                    className="d-none mr-16p"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleGtmTags("my insights")}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => handleGtmTags("my insights"))
                    }
                >
                    My Insights
                </div>
                <div
                    className="d-none mr-16p"
                    role="button"
                    tabIndex={0}
                    onClick={() => handleGtmTags("whats new")}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => handleGtmTags("whats new"))
                    }
                >
                    What’s new?
                </div>
                <div
                    className={
                        (url.includes("/preferences") ? "text-bold " : "") +
                        "mr-16p"
                    }
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        navigate("/preferences");
                        handleGtmTags("preferences");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            navigate("/preferences");
                            handleGtmTags("preferences");
                        })
                    }
                >
                    Preferences
                </div>
                <div
                    className={
                        (url.includes("/help") ? "text-bold " : "") + "mr-16p"
                    }
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (!isPreferenceChanged) {
                            navigate("/help");
                            handleGtmTags("help");
                        }
                        handleBackButtonClick("/help");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            if (!isPreferenceChanged) {
                                navigate("/help");
                                handleGtmTags("help");
                            }
                            handleBackButtonClick("/help");
                        })
                    }
                >
                    Help
                </div>
            </>
        );
    };

    const renderUserSpecificLinks = (
        user,
        returnUrl,
        config,
        handleGtmTags
    ) => {
        return (
            <>
                {returnUrl && returnUrl.includes("my.unum.com") && (
                    <div
                        className="d-flex mr-16p"
                        data-testid="MyUnum"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            window.location.replace(
                                config.REACT_APP_MY_UNUM,
                                "_blank"
                            );
                            handleGtmTags("myunum for clients");
                        }}
                        onKeyDown={(e) =>
                            handleKeyDown(e, () => {
                                window.location.replace(
                                    config.REACT_APP_MY_UNUM,
                                    "_blank"
                                );
                                handleGtmTags("myunum for clients");
                            })
                        }
                    >
                        MyUnum For Clients
                    </div>
                )}
                {user?.isUserInternal && (
                    <div
                        className="d-flex mr-16p"
                        data-testid="iservices"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            const redirectUrl =
                                config.REACT_APP_ISERVICES +
                                ":81/LanAuthentication";
                            window.open(redirectUrl, "_blank");
                            handleGtmTags("iservice");
                        }}
                        onKeyDown={(e) =>
                            handleKeyDown(e, () => {
                                const redirectUrl =
                                    config.REACT_APP_ISERVICES +
                                    ":81/LanAuthentication";
                                window.open(redirectUrl, "_blank");
                                handleGtmTags("iservice");
                            })
                        }
                    >
                        iServices
                        <img
                            className="ml-4p"
                            src={exportIcon}
                            alt="iService"
                        />
                    </div>
                )}
                {returnUrl !== null &&
                    !user?.isUserInternal &&
                    !returnUrl?.includes("my.unum.com") && (
                        <div
                            className="d-flex mr-16p"
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                let redirectUrl = returnUrl;
                                if (redirectUrl.includes("services.unum.com")) {
                                    redirectUrl =
                                        config.REACT_APP_ISERVICES +
                                        "/_fwWelc/Welcome.aspx";
                                }
                                window.open(redirectUrl, "_blank");
                                handleGtmTags("iservice");
                            }}
                            onKeyDown={(e) =>
                                handleKeyDown(e, () => {
                                    let redirectUrl = returnUrl;
                                    if (
                                        redirectUrl.includes(
                                            "services.unum.com"
                                        )
                                    ) {
                                        redirectUrl =
                                            config.REACT_APP_ISERVICES +
                                            "/_fwWelc/Welcome.aspx";
                                    }
                                    window.open(redirectUrl, "_blank");
                                    handleGtmTags("iservice");
                                })
                            }
                        >
                            iServices
                            <img
                                className="ml-4p"
                                src={exportIcon}
                                alt="iService"
                            />
                        </div>
                    )}
                <div
                    className="d-flex mr-16p"
                    data-testid="CLI"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        if (user?.isUserInternal) {
                            window.open(
                                config.REACT_APP_CLI_INTERNAL,
                                "_blank"
                            );
                        } else {
                            if (isCostcoUser(userSelectedOrganizations) && displayCostcoUrl) {
                                window.open(
                                    config.REACT_APP_COSTCO_URL,
                                    "_blank"
                                );
                            } else {
                                window.open(config.REACT_APP_CLI, "_blank");
                            }
                        }
                        handleGtmTags("cli");
                    }}
                    onKeyDown={(e) =>
                        handleKeyDown(e, () => {
                            if (user?.isUserInternal) {
                                window.open(
                                    config.REACT_APP_CLI_INTERNAL,
                                    "_blank"
                                );
                            } else {
                                window.open(config.REACT_APP_CLI, "_blank");
                            }
                            handleGtmTags("cli");
                        })
                    }
                >
                    CLI
                    <img className="ml-4p" src={exportIcon} alt="cli" />
                </div>
            </>
        );
    };

    return (
        <>
            <header id="myHeader">
                {renderInformationBanner(
                    showInformationBanner,
                    displayWarningBanner,
                    displayInformationBanner,
                    displayErrorbanner,
                    handleInformationBannerClose
                )}
                <div className="align-items-center container header pl-27p">
                    <div
                        className="sidebar-toggle cursor-pointer"
                        role="button"
                        tabIndex={0}
                        onClick={toggleSidebar}
                        ref={toggleRef}
                        title="Menu"
                        onKeyDown={(e) => handleKeyDown(e, toggleSidebar)}
                    >
                        <i
                            className={
                                "fa-solid text-primary-main " +
                                (sidebarOpen ? "fa-times" : "fa-bars")
                            }
                        ></i>
                    </div>
                    <img src={mainLogo} className="logo" alt="logo" />
                    <div className="d-flex header-text align-items-center nav-bar">
                        {renderNavigationLinks(
                            url,
                            isPreferenceChanged,
                            navigate,
                            handleGtmTags,
                            setPageSelected,
                            setPageGtmTags,
                            setShow
                        )}
                        {renderUserSpecificLinks(
                            user,
                            returnUrl,
                            config,
                            handleGtmTags
                        )}
                        {urlDisplay !== null && (
                            <div>
                                <OrganizationLogo
                                    organizationName={
                                        currentSelectedOrganization?.ORGNIZATIONNAME
                                    }
                                    card={false}
                                    label={false}
                                    height={30}
                                    width={101.99}
                                    div={false}
                                    style={{ marginRight: 16 }}
                                />
                            </div>
                        )}
                        <button
                            className="signout-button"
                            onClick={() => {
                                dispatch(logout());
                                gtm.Event(
                                    "top nav",
                                    "button",
                                    "click",
                                    "sign out",
                                    "top nav",
                                    "",
                                    "sign_out",
                                    ""
                                );
                                localStorage.clear();
                                sessionStorage.clear();
                            }}
                        >
                            Sign out
                        </button>
                    </div>
                </div>
                {url !== "/home" && <SubHeader />}
                {organization && (
                    <span className="d-none" id="employer-name">
                        {organization?.ORGNIZATIONNAME}
                    </span>
                )}
                {user && (
                    <span className="d-none" id="is-internal-user">
                        {user?.isUserInternal === true ? "Yes" : "No"}
                    </span>
                )}
                {user && (
                    <span className="userid" hidden>
                        {user?.userId}
                    </span>
                )}
                {user && (
                    <span className="user-email" hidden>
                        {user?.userEmail}
                    </span>
                )}
                {user && (
                    <span className="username" hidden>
                        {user?.userName}
                    </span>
                )}
            </header>
            {isPreferenceSaved && (
                <PreferenceSaveNotification
                    handleSavedPreference={handleSavedPreference}
                />
            )}
            <Sidebar
                open={sidebarOpen}
                closeSidebar={closeSidebar}
                toggleRef={toggleRef}
            />
            <WarningPage
                onShow={show}
                onSave={emitEvent}
                onDontSave={dontSave}
            />
        </>
    );
}

export default Header;
