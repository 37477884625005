import React from "react";
import Table from "../../../components/table/Table";
import claimstatusreasons from "./claimstatusreasons.json";

const ClaimStatusReason = () => {
    const columns = React.useMemo(
        () => [
            {
                Header: "Claim Status Reason",
                columns: [
                    {
                        Header: "Claim Status",
                        accessor: "Status",
                        disableSortBy: true,
                        width: 180,
                    },
                    {
                        Header: "Status Reason",
                        accessor: "Status Reason",
                        disableSortBy: true,
                        width: 150,
                    },
                    {
                        Header: "Definition",
                        accessor: "Definition",
                        disableSortBy: true,
                        width: 480,
                    },
                ],
            },
        ],
        []
    );

    return (
        <div>
            <Table
                columns={columns}
                data={claimstatusreasons}
                responsiveConfig={{
                    type: "COLUMN",
                    striped: false,
                    screen: "md",
                }}
                pagination={false}
                className="reference-table"
            />
            <div className="claimstatusreasons-footer">
                Please note: If no benefits are paid, a claim is considered
                denied (Non-compensable) If benefits were paid, the claim is
                considered terminated *'Claim Withdrawn' and 'Claim Withdrawn -
                Non-Compensable' are both technically terminations, but one was
                paid and the other was not, so this is the exception to the
                above
            </div>
        </div>
    );
};

export default ClaimStatusReason;
