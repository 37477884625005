import React from "react";

const UserGuide = () => {
    return (
        <>
            <div className="diagnostic-paragraph">Introduction to search</div>
            <div className="d-flex justify-content-center pt-16p pb-20p">
                <iframe
                    className="user-guide"
                    src="https://player.vimeo.com/video/812041431"
                    width="640"
                    height="360"
                    frameBorder="0"
                    allow="fullscreen; picture-in-picture allowFullScreen"
                    title="User Guide Video"
                ></iframe>
            </div>
        </>
    );
};

export default UserGuide;
