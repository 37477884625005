import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../config";
import DataViewFormatter from "../models/ReportDetails";

const saveReportViewSlice = createApi({
    reducerPath: "saveReportView",

    baseQuery: fetchBaseQuery({
        baseUrl: config.REACT_APP_API_URL,
        credentials: "include",
        prepareHeaders: (headers) => {
            headers.set("Accept", "application/json");
            headers.set("Content-Type", "application/json");

            const apiKey = process.env.REACT_APP_API_KEY;
            if (apiKey) headers.set("apikey", apiKey);

            return headers;
        },
    }),
    endpoints: (builder) => ({
        saveReportView: builder.mutation({
            query: ({ dataToUpdate }) => ({
                url: `/data-views/${process.env.REACT_APP_API_VERSION}${config.REACT_APP_ENV_NAME}`,
                method: "POST",
                body: JSON.stringify(dataToUpdate),
            }),
            invalidatesTags: (_) => ["reportDetails"],
            transformResponse: (response) => {
                return response;
            },
        }),
        getReportDetails: builder.query({
            query: ({
                isfUserId,
                organizationGroupId,
                dataViewType,
                selectedIsfOrganizationIds,
            }) => ({
                url: `/data-views/${process.env.REACT_APP_API_VERSION}${config.REACT_APP_ENV_NAME}`,
                method: "GET",
                params: {
                    isfUserId: isfUserId,
                    organizationGroupId: organizationGroupId,
                    dataViewType: dataViewType,
                    selectedIsfOrganizationIds: selectedIsfOrganizationIds,
                },
            }),
            invalidatesTags: (_) => ["reportDetails"],
            transformResponse: (response) => {
                return DataViewFormatter.format(response);
            },
        }),
        updateReportDetails: builder.mutation({
            query: ({ dataToUpdate, viewId }) => ({
                url: `/data-views/${process.env.REACT_APP_API_VERSION}/${viewId}${config.REACT_APP_ENV_NAME}`,
                method: "PUT",
                body: JSON.stringify(dataToUpdate),
            }),
        }),
        deleteReportView: builder.mutation({
            query: ({ dataViewType }) => ({
                url: `/data-views/${process.env.REACT_APP_API_VERSION}${config.REACT_APP_ENV_NAME}`,
                method: "DELETE",
                params: {
                    viewIds: dataViewType.viewIds,
                },
            }),
            invalidatesTags: (_) => ["reportDetails"],
        }),
        reportEnabledOrgs: builder.query({
            query: () => ({
                url: `/data-views/${process.env.REACT_APP_API_VERSION}/organizations${config.REACT_APP_ENV_NAME}`,
                method: "GET",
            }),
            invalidatesTags: (_) => ["reportDetails"],
        }),
        updateFrequentlyUsedReports: builder.mutation({
            query: ({ parametrs, viewId }) => ({
                url: `/data-views/${process.env.REACT_APP_API_VERSION}/${viewId}/metrics${config.REACT_APP_ENV_NAME}`,
                method: "PUT",
                body: JSON.stringify(parametrs),
            }),
            invalidatesTags: (_) => ["reportDetails"],
        }),
    }),
});

export const {
    useSaveReportViewMutation,
    useLazyGetReportDetailsQuery,
    useGetReportDetailsQuery,
    useUpdateReportDetailsMutation,
    useDeleteReportViewMutation,
    useLazyReportEnabledOrgsQuery,
    useReportEnabledOrgsQuery,
    useUpdateFrequentlyUsedReportsMutation,
} = saveReportViewSlice;

export default saveReportViewSlice;
