import { createSlice } from "@reduxjs/toolkit";

const preferenceSlice = createSlice({
    name: "preferenceSlice",
    initialState: {
        homePreference: [],
        organizationPreference: [],
        searchPreference: [],
        loginMessage: "",
        isPreferenceChanged: false,
        isPreferencedDataFetched: false,
    },
    reducers: {
        setUserPreference(state, action) {
            state.homePreference = action.payload.homePreference;
            state.organizationPreference =
                action.payload.organizationPreference;
            state.searchPreference = action.payload.searchPreference;
        },
        setPreferenceChanged(state, action) {
            state.isPreferenceChanged = action.payload;
        },
        setLoginMessage(state, action) {
            state.loginMessage = action.payload.loginMessage;
        },
        setPreferenceDataFetched(state, action) {
            state.isPreferencedDataFetched = action.payload;
        },
    },
});

export const {
    setUserPreference,
    setPreferenceChanged,
    setLoginMessage,
    setPreferenceDataFetched,
} = preferenceSlice.actions;

export default preferenceSlice.reducer;
