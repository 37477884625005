import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Button from "./ui/Button";
import preference from "../assets/icons/preference-icon.svg";
import help from "../assets/icons/help-icon.svg";
import whatsnew from "../assets/icons/whats-new-icon.svg";

function InsightInfo() {
    const navigate = useNavigate();

    const handleHelpButtonClick = useCallback(() => {
        navigate("/help");
    }, [navigate]);

    const handleMyInsightsButtonClick = useCallback(() => {
        navigate("/preferences");
    }, [navigate]);

    return (
        <div className="d-flex row insightinfo" style={{ margin: 0 }}>
            <div className="section-card homepagecard">
                <div className="d-flex align-items-start mx-4p">
                    <img className=" mx-4p" src={help} alt="Help" />
                    <h5 className="text-color text-bold mb-0p">Help</h5>
                </div>
                <p className="pt-16p">
                    For support, troubleshooting user guides, and good to know
                    acronyms.
                </p>
                <div className="buttontext myinsightbutton">
                    <Button
                        onClick={handleHelpButtonClick}
                        text="Visit resources"
                    />
                </div>
            </div>
            <div className="section-card homepagecard d-none">
                <div className="d-flex align-items-start mx-4p">
                    <img className="mx-4p" src={whatsnew} alt="New features" />
                    <h5 className="text-color text-bold mb-0p">New features</h5>
                </div>
                <p className="pt-16p">
                    We're excited to announce new product features will be
                    rolling out soon.
                </p>
                <div className="buttontext">
                    <Button text="Coming soon" disabled />
                </div>
            </div>
            <div className="section-card homepagecard d-none">
                <div className="d-flex align-items-start mx-4p">
                    <img className="mx-4p" src={whatsnew} alt="What's New" />
                    <h5 className="text-color text-bold mb-0p">What’s new?</h5>
                </div>
                <p className="pt-16p">
                    See the latest updates, including new features and fixes to
                    Unum Insights.
                </p>
                <div className="buttontext">
                    <Button text="Discover more" />
                </div>
            </div>
            <div className="section-card homepagecard">
                <div className="d-flex align-items-start mx-4p ">
                    <img
                        className=" mx-4p"
                        src={preference}
                        alt="Preferences"
                    />
                    <h5 className="text-color text-bold mb-0p">Preferences</h5>
                </div>
                <p className="pt-16p">
                    You can configure Insights settings to make it work better
                    for you.
                </p>
                <div className="buttontext myinsightbutton">
                    <Button
                        onClick={handleMyInsightsButtonClick}
                        text="Visit Preferences"
                    />
                </div>
            </div>
        </div>
    );
}

export default InsightInfo;
