import React, { useEffect } from "react";
import { useGetUserQuery } from "./slices/userApiSlice";
import { login } from "./slices/authSlice";
import { useDispatch, useSelector } from "react-redux";
import cookieService from "./services/CookieService";
import { useNavigate } from "react-router-dom";
import config from "./config";
import { getLogoutRedirectUrl } from "./utils/helpers";
import { setUserDetails } from "./slices/userSlice";
import { useReportEnabledOrgsQuery } from "./slices/saveReportViewSlice";
import {
    addLastSelectedOrganization,
    addSelectedOrganization,
    clearSelectedOrganizations,
    setTotalParentOrganization,
    setOrganizationsCount,
    setSelectedOrgainzationsId,
} from "./slices/organizationSlice";
import UserRepository from "./repositories/UserRepository";
import apiSlice from "./slices/apiSlice";
import ProgressBar from "./components/ProgressBar";
import {
    addSelectedOrganizationForReports,
    clearSelectedOrganizationsForReports,
    setSelectedOrgainzationsIdForReports,
    setSelectedRelatedOrganizationsForReports,
} from "./slices/viewReportSlice";
import {
    setLoginMessage,
    setUserPreference,
    setPreferenceDataFetched,
} from "./slices/preferenceSlice";
import { useLazyGetUserPreferenceDataQuery } from "./slices/userPreferenceApiSlice";
import OrganizationRepository from "./repositories/OrganizationRepository";
import { useFlags } from "launchdarkly-react-client-sdk";

function Login() {
    const { data: users = null, isFetching, error } = useGetUserQuery();
    const { data: reportEnabledOrganizations } = useReportEnabledOrgsQuery({});
    const { user, deepLinkUrl, dlselectedIsfOrganizationId } = useSelector(
        (state) => state.auth
    );
    const { organizationPreference, homePreference } = useSelector(
        (state) => state.preferenceSlice
    );
    const { organizations: allOrganizations } = useSelector(
        (state) => state.userDetails
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { displayReports } = useFlags();

    const [
        getUserPreferenceData,
        { data: preferenceData, isSuccess: preferenceDataLoaded },
    ] = useLazyGetUserPreferenceDataQuery();

    useEffect(() => {
        if (error && (error.status === 401 || error.originalStatus === 401)) {
            window.location = getLogoutRedirectUrl();
        }
    }, [error]);

    useEffect(() => {
        if (users !== null) {
            const { userDetails, policies } =
                UserRepository.formatUserResponse(users);

            let organizations =
                UserRepository.formatUserResponse(users).organizations;

            const cookieToken = cookieService.get(
                config.REACT_APP_SSO_COOKIE_NAME
            );

            dispatch(clearSelectedOrganizations());
            dispatch(clearSelectedOrganizationsForReports());
            dispatch(apiSlice.util.resetApiState());

            dispatch(
                login({
                    user: userDetails,
                    token: cookieToken,
                })
            );
            dispatch(
                setUserDetails({
                    organizations: organizations,
                    policies: policies,
                    reportEnabledOrgs: reportEnabledOrganizations,
                })
            );

            let isAllSame = true;
            const relatedOrganizations = [];

            if (organizations.length > 0) {
                const firstOrganizationGroupId =
                    organizations[0].ORGANIZATIONGROUPID;

                for (const item of organizations) {
                    const organizationGroupId = item.ORGANIZATIONGROUPID;

                    if (
                        organizationGroupId === null ||
                        organizationGroupId !== firstOrganizationGroupId
                    ) {
                        isAllSame = false;
                        break;
                    }

                    relatedOrganizations.push(item);
                }
            }

            let checkOrganizationLength =
                organizations.length === 1 ? "true" : "false";

            if (isAllSame || organizations.length === 1) {
                let selectedOrganization = [...organizations].sort((a, b) =>
                    a.ORGNIZATIONNAME.localeCompare(b.ORGNIZATIONNAME)
                );

                dispatch(
                    addSelectedOrganization({
                        id: selectedOrganization[0].ORGANIZATIONID,
                        organization: selectedOrganization[0],
                        related: relatedOrganizations,
                    })
                );
                dispatch(
                    setSelectedOrgainzationsId(
                        selectedOrganization[0].ORGANIZATIONID
                    )
                );
                dispatch(
                    addSelectedOrganizationForReports({
                        id: selectedOrganization[0].ORGANIZATIONID,
                        organization: selectedOrganization[0],
                        related: relatedOrganizations,
                    })
                );

                dispatch(
                    setSelectedOrgainzationsIdForReports(
                        selectedOrganization[0].ORGANIZATIONID
                    )
                );
                dispatch(addLastSelectedOrganization(selectedOrganization[0]));

                dispatch(setOrganizationsCount(checkOrganizationLength));

                dispatch(setTotalParentOrganization(1));
            } else if (
                (preferenceData !== null || preferenceDataLoaded !== null) &&
                organizationPreference?.length > 0 &&
                organizationPreference?.toString() !== ""
            ) {
                const preferenceStoredOrg =
                    OrganizationRepository.getRelatedOrganizationsFromIds(
                        allOrganizations,
                        organizationPreference
                    );

                if (organizationPreference.length > 0) {
                    dispatch(
                        addSelectedOrganization({
                            id: preferenceStoredOrg[0]?.ORGANIZATIONID,
                            organization: preferenceStoredOrg[0],
                            related:
                                preferenceStoredOrg.length > 1
                                    ? preferenceStoredOrg
                                    : [],
                        })
                    );

                    dispatch(
                        addLastSelectedOrganization(preferenceStoredOrg[0])
                    );
                    dispatch(setTotalParentOrganization(organizations.length));
                    dispatch(setOrganizationsCount(isAllSame));
                    dispatch(
                        addSelectedOrganizationForReports({
                            id: preferenceStoredOrg[0]?.ORGANIZATIONID,
                            organization: preferenceStoredOrg[0],
                            related:
                                preferenceStoredOrg?.length > 0 &&
                                preferenceStoredOrg[0]?.ORGANIZATIONGROUPID !==
                                    null
                                    ? preferenceStoredOrg
                                    : [],
                        })
                    );

                    dispatch(
                        setSelectedOrgainzationsIdForReports(
                            preferenceStoredOrg[0]?.ORGANIZATIONID
                        )
                    );
                    const selected = [];
                    Object.values(preferenceStoredOrg).forEach((item) => {
                        if (
                            organizationPreference.includes(
                                item.ORGANIZATIONID.toString()
                            )
                        ) {
                            const option = {
                                value: item.ORGANIZATIONID,
                                label: item.ORGNIZATIONNAME,
                                organizationIDISF: item.ORGANIZATIONIDISF,
                                organizationGroupId: item.ORGANIZATIONGROUPID,
                            };
                            selected.push(option);
                        }
                    });

                    dispatch(
                        setSelectedRelatedOrganizationsForReports(selected)
                    );
                }
            } else {
                dispatch(setTotalParentOrganization(organizations.length));
                dispatch(setOrganizationsCount(isAllSame));
            }
        }
    }, [
        users,
        preferenceDataLoaded,
        dispatch,
        preferenceData,
        organizationPreference,
    ]);
    useEffect(() => {
        if (user !== null) {
            getUserPreferenceData({
                userId: user?.userId,
            });
        }
    }, [user]);

    useEffect(() => {
        if (user !== null && preferenceDataLoaded === true) {
            let preference = preferenceData?.UserPreferences;
            let loginMessage = preferenceData?.LoginMessage;
            let homePreference =
                preference?.HomePreference === undefined
                    ? [""]
                    : preference?.HomePreference;

            dispatch(
                setUserPreference({
                    homePreference: homePreference,
                    organizationPreference: preference?.OrganizationPreference,
                    searchPreference: preference?.SearchPreference,
                })
            );
            dispatch(setPreferenceDataFetched(true));
            dispatch(setLoginMessage({ loginMessage: loginMessage }));

            if (deepLinkUrl === "rc" || dlselectedIsfOrganizationId !== "") {
                navigate("/reportingcapabilities");
            } else if (displayReports) {
                if (homePreference && homePreference.length > 0) {
                    navigate(`/${homePreference?.toString()}`);
                } else {
                    navigate("/home");
                }
            } else {
                navigate("/search");
            }
        }
    }, [user, preferenceDataLoaded, preferenceData]);

    return <div>{isFetching && <ProgressBar />}</div>;
}

export default Login;
