import React from "react";
import SectionHeading from "../../components/SectionHeading";
import Button from "../../components/ui/Button";
import Modal from "react-bootstrap/Modal";
import GTM from "../../GTM";

export default function ReportDeletePage({
    onShow,
    onSave,
    onDontSave,
    selectedView,
}) {
    const handleGtmTags = (label, attribute3) => {
        gtm.Event(
            "manage my reports",
            "button",
            "click",
            label,
            "report detail",
            selectedView.DataViewType.toLowerCase(),
            attribute3,
            ""
        );
    };

    const sendSaveEvent = () => {
        onSave();
        handleGtmTags("delete report", "delete_report");
    };
    const sendDontSaveEvent = () => {
        onDontSave();
        handleGtmTags("cancle deletion", "field_interaction");
    };
    const gtm = new GTM(null);

    return (
        <>
            <Modal
                size="sm"
                show={onShow}
                backdrop="static"
                keyboard={false}
                centered
                className="preference"
                dialogClassName="custom-modal-size"
            >
                <Modal.Header>
                    <SectionHeading heading="Delete this report?" />
                    <i
                        className="fa-solid fa-circle-xmark cursor-pointer"
                        onClick={sendDontSaveEvent}
                    ></i>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <label>
                                {selectedView
                                    ? selectedView.Name
                                    : "Default Name"}
                            </label>
                        </div>
                    </div>

                    <div className="preference-button pt-16p">
                        <div className="d-contents">
                            <Button
                                data-testid="Cancel"
                                text="Cancel"
                                outline={true}
                                onClick={sendDontSaveEvent}
                            />
                            <Button text="Delete" onClick={sendSaveEvent} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
