class PortalUserResponse {
    format = (response) => {
        const userPermissions = {
            userIdentifier: response.userIdentifier,
            internalUserInd: response.internalUserInd,
            partyIdentifier: response.partyIdentifier,
        };

        if (
            response.assignedOrgsList &&
            Array.isArray(response.assignedOrgsList)
        ) {
            const assignedOrgs = response.assignedOrgsList.map(
                (organization) => {
                    const assignedPolicies = [];

                    if (
                        organization.assignedPolicies &&
                        Array.isArray(organization.assignedPolicies)
                    ) {
                        const sortedPolicies =
                            organization.assignedPolicies.sort(
                                (a, b) =>
                                    a.policyId.localeCompare(b.policyId) ||
                                    a.divisionId.localeCompare(b.divisionId)
                            );

                        sortedPolicies.forEach((policy) => {
                            assignedPolicies.push({
                                policyId: policy.policyId,
                                divisionId: policy.divisionId,
                                divisionName: policy.divisionName,
                            });
                        });
                    }

                    return {
                        orgId: organization.orgId,
                        orgName: organization.orgName,
                        assignedPolicies: assignedPolicies,
                    };
                }
            );

            userPermissions.assignedOrgs = assignedOrgs.sort((a, b) =>
                a.orgId.localeCompare(b.orgId)
            );
        }

        return { userPermissions };
    };
}

export default new PortalUserResponse();
