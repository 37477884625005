import { createEnum } from "../utils/helpers";
import COSTCO_LOGO from "../assets/logo/Costco_Wholesale_logo.svg";

// Datatable
export const paginationOptions = [5, 10, 50, 100, 200];
export const defaultPaginationItems = 10;

export const cacheDataForInSeconds = 300;

export const recordsType = createEnum(["LEAVE", "CLAIM"]);

export const ssnAccess = createEnum(["No Access", "Full Access", "Partial"]);

export const noDataPlaceholder = "—";

export const displayLogo = /organization\/[^\/]+\/employees\/[^\/]+/g;

const logos = {
    costco: COSTCO_LOGO,
};

export default logos;

export const dateFormat = "MM/DD/YYYY";
export const ownerTypeOrder = ["Personal", "Shared", "Standard"];
export const dataViewTypeOrder = [
    "Disability",
    "Leave",
    "Absence",
    "Payments",
    "Life",
];
