import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearHistory } from "../slices/historySlice";
import { useFlags } from "launchdarkly-react-client-sdk";

function Redirect() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        displayReports,
        displayReportsForInternals,
        displayReportsForChangeChampions,

        displayReportsForOrgs,
    } = useFlags();
    const enabledUsersForReports = displayReportsForChangeChampions?.split(",");
    const { user } = useSelector((state) => state.auth);
    const loggedInUser = useSelector((state) => state.auth.user);
    const { totalParentOrganizations, selectedOrganizations } = useSelector(
        (state) => state.organizations
    );
    const {
        organizations: allOrganizations,

        reportEnabledOrganizations: reportEnabledOrganizations,
    } = useSelector((state) => state.userDetails);
    const stateHistory = useSelector((state) => state.history);
    const homePreference = useSelector(
        (state) => state.preferenceSlice.homePreference
    );

    const shouldRedirectToReport =
        (displayReportsForChangeChampions &&
            enabledUsersForReports?.includes(user.userId)) ||
        (displayReportsForOrgs &&
            reportEnabledOrganizations?.EnabledOrganizations?.some((org) =>
                allOrganizations
                    .map((org) => org.ORGANIZATIONIDISF)
                    .includes(org)
            ));
    useEffect(() => {
        if (!loggedInUser) {
            navigate("/login");
            return;
        }

        if (Object.keys(stateHistory).length > 0) {
            dispatch(clearHistory());
        }

        if (
            totalParentOrganizations === 1 &&
            Object.keys(selectedOrganizations).length > 0
        ) {
            const organization = Object.values(selectedOrganizations)[0]
                .organization;

            navigate(
                !shouldRedirectToReport ||
                    homePreference?.toString() === "search"
                    ? `/organization/${organization.ORGANIZATIONID}/employees/search`
                    : `/home`,
                { replace: true }
            );
        } else {
            navigate(
                shouldRedirectToReport ||
                    (displayReportsForInternals && user.isUserInternal)
                    ? "/home"
                    : "/search"
            );
        }
    }, [
        loggedInUser,
        totalParentOrganizations,
        selectedOrganizations,
        displayReports,
        homePreference,
        stateHistory,
        dispatch,
        navigate,
    ]);

    return null;
}

export default Redirect;
